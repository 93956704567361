import { StorageKeys } from "@/consts";
import api from "@/services/api";
import { isAvgWebLoginPage, parseSearchParams } from "@/utils/avgUtil";
import { generateUUID } from "@/utils/common";
import { storageSy } from "@/utils/setting";
import qs from "qs";
import { RequestConfig, history, matchRoutes } from "umi";
import { getInfo } from "./services/user";
import avgHandler from "./utils/avgHandler";
import { config } from "./utils/config";
import { setCookie } from "./utils/editorUtils";

import {
  errorHandler,
  errorThrower,
  requestInterceptors,
  responseInterceptors,
} from "./utils/request";

const limitPath = "/limit";
const langMap = {
  zh_CN: "zh-CN",
  zh_TW: "zh-TW",
  en_US: "en-US",
};

const searchParams = new URLSearchParams(location.search);

if (searchParams.has(StorageKeys.INVITER_USER_ID)) {
  sessionStorage.setItem(
    StorageKeys.INVITER_USER_ID,
    searchParams.get(StorageKeys.INVITER_USER_ID) as string
  );
}
if (searchParams.has(StorageKeys.INVITER_CODE)) {
  sessionStorage.setItem(
    StorageKeys.INVITER_CODE,
    searchParams.get(StorageKeys.INVITER_CODE) as string
  );
}

// 切换 sdk 的语言
try {
  if (avgHandler.isSDK()) {
    const lang = avgHandler.getLanguage();
    const locale = langMap[lang as keyof typeof langMap] || "zh-CN";
    localStorage.setItem(StorageKeys.UMI_LOCALE, locale);
  }
} catch (error) {
  console.error("设置语言失败：", error);
}

// 生成浏览器指纹
try {
  // 使用 IIFE 来处理异步操作
  (async () => {
    const deviceId = localStorage.getItem(StorageKeys.DEVICE_ID);
    if (!deviceId) {
      const fingerprintId = await generateUUID();
      localStorage.setItem(StorageKeys.DEVICE_ID, fingerprintId);
      console.log("生成设备指纹ID:", fingerprintId);
    }
  })();
} catch (error) {
  console.error("设备指纹生成失败：", error);
}

// 临时解决域名方案
const getApiUrl = () => {
  if (typeof window !== "undefined") {
    const hostname = window.location.hostname;

    // 处理 localhost 或 IP 地址
    if (hostname === "localhost" || /^\d+\.\d+\.\d+\.\d+$/.test(hostname)) {
      return config.host;
    }

    try {
      // 直接使用当前域名
      return `https://${hostname}`;
    } catch (error) {
      console.error("Error determining API URL:", error);
      return config.host;
    }
  }

  return config.host;
};

export const request: RequestConfig = {
  timeout: 10000,
  baseURL: process.env.NODE_ENV === "development" ? config.host : getApiUrl(),
  errorConfig: {
    errorHandler,
    errorThrower,
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
  requestInterceptors,
  responseInterceptors,
};
export function onRouteChange(data: any) {
  const { clientRoutes, location } = data;

  // 此处判断来源url是否带token带token则为游戏内购买点卡的跳转链接
  const filterToken = parseSearchParams(location.search);
  if (filterToken && filterToken.token) {
    // 默认来源为游戏内购买月卡跳转
    localStorage.setItem(StorageKeys.TOKEN, filterToken.token);
    localStorage.setItem(StorageKeys.LIMIT, "accept");
    if (["sdd"].includes(filterToken.source)) {
      history.replace("/recharge?tabType=1");
      return;
    }
  }
  const route = matchRoutes(clientRoutes, location.pathname)?.pop()
    ?.route as any;

  if (!route && location.pathname !== "/") {
    history.push("/");
  }

  if (document) {
    document.title =
      "MASOBU 麻涩部｜真人互動遊戲平台，成人真人遊戲推薦 - 官方網站";
    console.log("document.title: ", document.title);
  }

  const limitStatus = localStorage.getItem(StorageKeys.LIMIT);
  if (
    limitStatus !== "accept" &&
    location.pathname !== limitPath &&
    !avgHandler.isSDK()
  ) {
    // 记录当前地址
    sessionStorage.setItem(StorageKeys.ORIGIN_URL, window.location.href);
    history.push(limitPath);
  }
}
// 获取初始数据以便后续全局使用，例如用户信息。
export async function getInitialState(): Promise<{
  currentUser?: UserInfo | undefined;
  fetchUserInfo?: () => Promise<UserInfo | undefined>; // 获取用户信息接口
  checkIsLogin?: () => Promise<any>;
  showBottomDialog: boolean;
  showSearchDialog: boolean;
}> {
  let currentUser: UserInfo | undefined = {} as UserInfo;

  // 此处判断来源url是否带token带token则为游戏内购买点卡的跳转链接
  const filterToken = parseSearchParams(location.search);
  if (filterToken && filterToken.token) {
    // 默认来源为游戏内购买月卡跳转 存储本地token
    localStorage.setItem(StorageKeys.TOKEN, filterToken.token);
  }
  // 应用加载时触发
  try {
    let dc = searchParams.get("dc") || localStorage.getItem(StorageKeys.DC);
    const postData: any = {};
    if (dc) {
      postData.dc = dc;
      localStorage.setItem(StorageKeys.DC, dc);
    }
  } catch (error) {
    console.error("处理 DC 参数失败：", error);
  }

  if (avgHandler.isSDK()) {
    // 当为login 和 device-login时不设置
    if (!isAvgWebLoginPage()) {
      const _token = avgHandler.getToken();
      localStorage.setItem(storageSy.token, _token);
    }
  }
  const USER_TOKEN = localStorage.getItem(storageSy.token);
  const fetchUserInfo = async () => {
    if (isAvgWebLoginPage()) {
      return undefined;
    }
    try {
      const res = await getInfo();
      
      setCookie(StorageKeys.u_id, res.data?.userId);

      localStorage.setItem(
        "userInfo",
        JSON.stringify(res.data ? res.data : {})
      );
      return res.data as any;
    } catch (error) {
      console.log(error, "error");
    }
    return undefined;
  };
  if (USER_TOKEN) {
    currentUser = (await fetchUserInfo()) as any;
  } else {
    // 当为unity环境拉起页面时不请求
    if (!avgHandler.isSDK()) {
      const dc =
        searchParams.get("dc") || localStorage.getItem(StorageKeys.DC) || "";
      const deviceId =
        localStorage.getItem(StorageKeys.DEVICE_ID) || (await generateUUID());
      if (deviceId) {
        const res = await api.user.guestLogin(deviceId, dc);
        if (res.code === 200) {
          localStorage.setItem(storageSy.token, res.data.token);
          currentUser = (await fetchUserInfo()) as any;
        }
      }
    }
  }
  const checkIsLogin = () => {
    return new Promise((resolve) => {
      const token = localStorage.getItem(StorageKeys.TOKEN);
      if (token) {
        resolve(true);
      } else {
        resolve(false);
        // history.push('/login')
      }
    });
  };

  return {
    currentUser,
    fetchUserInfo,
    checkIsLogin,
    showBottomDialog: false,
    showSearchDialog: false,
  };
}
