import { useCallback, useState } from 'react';

// Model 示例，文档：https://umijs.org/docs/max/data-flow
export default function Page() {
  const [counter, setCounter] = useState(0);

  const increment = useCallback(() => setCounter((c) => c + 1), []);
  const decrement = useCallback(() => setCounter((c) => c - 1), []);

  return { counter, increment, decrement };
}
