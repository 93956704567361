// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

/** 邮箱验证码 邮箱验证码 POST /c/captcha/getEmailCaptcha */
export async function getEmailCaptcha(
  body: API.SendEmailCaptchaReq,
  options?: { [key: string]: any },
) {
  return request<API.RSendCaptchaResp>('/c/captcha/getEmailCaptcha', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 手机验证码 手机验证码 POST /c/captcha/getPhoneCaptcha */
export async function getPhoneCaptcha(
  body: API.SendPhoneCaptchaReq,
  options?: { [key: string]: any },
) {
  return request<API.RSendCaptchaResp>('/c/captcha/getPhoneCaptcha', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
