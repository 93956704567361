// @ts-ignore
/* eslint-disable */
import { request } from "umi";

/** 获取终端内容配置 获取终端内容配置 GET /c/contentConfig/get */
export async function get3(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.get3Params,
  options?: { [key: string]: any }
) {
  return request<API.RListGetContentConfigRespObject>("/c/contentConfig/get", {
    method: "GET",
    params: {
      ...params,
      req: undefined,
      ...params["req"],
    },
    ...(options || {}),
  });
}

// 获取 18+ 配置
export async function getLimitConfig() {
  return request<API.GetLimitConfigResp>(
    "/c/contentConfig/getEighteenWarnConfig",
    {
      method: "GET",
    }
  );
}

// 获取首页内容配置
export async function getIndexConfig(params: API.GetIndexConfigReq) {
  return request<API.GetIndexConfigResp>("/c/contentConfig/getIndexConfig", {
    method: "GET",
    params,
  });
}

// 查询运营活动信息
export async function getActivityConfig(params: { terminal: string }) {
  return request<API.ActivityResp>("/c/content/activity/get", {
    method: "GET",
    params,
  });
}

// 获取联系方式
export async function getContactConfig() {
  return request<API.GetContactConfigResp>(
    "/c/contentConfig/getContactConfig",
    {
      method: "GET",
    }
  );
}

// 获取联系方式群组
export async function getContactConfigGroup() {
  return request("/c/contentConfig/getContactConfigGroup", {
    method: "GET",
  });
}

// 获取资源位信息
export async function getResourceNiche(terminal: string) {
  return request("/c/contentConfig/getResourceNiche", {
    method: "GET",
    params: {
      terminal,
    },
  });
}
