interface Config {
  host?: string;
  gaCode?: string;
}

const configEnvMap: Record<string, Config> = {
  dev: {
    host: "https://test-wap.moyibian.com",
    gaCode: "G-NF1YEE3CB2",
  },

  test: {
    host: "https://test-gw.moyibian.com",
    gaCode: "G-NF1YEE3CB2",
  },

  pre: {
    host: "https://pre-gw.moyibian.com",
    gaCode: "G-NF1YEE3CB2",
  },

  prod: {
    host: "https://gw.masobu.com",
    // host: 'https://gw.bxcidypkj.com',
    gaCode: "G-C3YC40Q250",
  },
};
const config = configEnvMap[process.env.REACT_APP_ENV || "prod"];
export { config };
