// @ts-ignore
/* eslint-disable */
import { request } from "umi";

/** 邮件登录 邮件登录 POST /c/login/emailLogin */
export async function emailLogin(
  body: API.EmailLoginReq,
  options?: { [key: string]: any }
) {
  return request<API.RLoginResp>("/c/login/emailLogin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 退出登录 退出登录 POST /c/login/logout */
export async function logout(options?: { [key: string]: any }) {
  return request<API.RVoid>("/c/login/logout", {
    method: "POST",
    ...(options || {}),
  });
}

/** 手机号码登录 手机号码登录 POST /c/login/phoneLogin */
export async function phoneLogin(
  body: API.PhoneLoginReq,
  options?: { [key: string]: any }
) {
  return request<API.RLoginResp>("/c/login/phoneLogin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 游客绑定手机号 绑定手机号 POST /c/user/guest/bindPhone */
export async function guestBindPhone(
  body: API.PhoneLoginReq,
  options?: { [key: string]: any }
) {
  return request<API.RLoginResp>("/c/user/guest/bindPhone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}
