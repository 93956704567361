import { request } from 'umi'

// 查询我的可用券
export async function myValidCouponList (params: {
  pageSize: number
  pageNum: number
}) {
  return request<API.MyValidCouponListResp>('/c/coupon/myValidCouponList', {
    method: 'GET',
    params
  })
}

// 查询我的失效券
export async function myInvalidCouponList (params: {
  pageSize: number
  pageNum: number
}) {
  return request<API.MyValidCouponListResp>('/c/coupon/myInvalidCouponList', {
    method: 'GET',
    params
  })
}

// 通过口令领取优惠券
export async function getCouponByCommandRange (commandRange: string) {
  return request<API.GetCouponByCommandRangeResp>('/c/coupon/getCouponByCommandRange', {
    method: 'POST',
    data: {
      commandRange
    }
  })
}

// 查看优惠券使用游戏列表
export async function queryUseGameListByCouponId (couponId: string) {
  return request<API.GetGameInfoRespT>('/c/coupon/queryUseGameListByCouponId', {
    method: 'GET',
    params: {
      couponId
    }
  })
}
