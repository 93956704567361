export default {
  "tab.menu": "Menu",
  "tab.myAccount": "Account",
  "tab.signIn": "Sign In",
  "tab.hall": "Game Hall",
  "tab.task": "Task",
  "tab.pay": "Voucher Center",
  "tab.slide": "slide",
  "menu.home": "Home",
  "menu.redeem-codes": "Redeem Codes",
  "menu.online-game": "Online Games",
  "menu.offline-game": "Game List",
  "menu.h5-game": "H5 Games",
  "menu.virtual-coin": "Recharge Center",
  "menu.game-detail": "Game Details",
  "menu.game-auditing": "Game Auditing",
  "menu.account-center": "Account Center",
  "menu.age-limit": "Age Limit",
  "menu.privacy": "Privacy Notice",
  "menu.server": "Terms of Use",
  "menu.search": "Search",
  "menu.activity": "Activity Center",
  "menu.vipPrefecture": "Vip Prefecture",
  "menu.signUp": "Sign Up",
  "menu.currentDevice": "Current Logged-in Device",
  "menu.lang": "Language",
  "menu.toUp": "Deposit",
  "menu.payVip": "Renew prepaid card",
  "menu.energy": "energy",
  "menu.mEnergy": "M Energy",
  "menu.payVipCard": "Pay Vip Card",
  "menu.payVipCardTip": "Unlock and enjoy more cashback rewards!",
  "menu.bindAccount": "Bind Account",
  "menu.loginAccount": "Log in to Existing Account",
  "lang.cancel": "Cancel",
  "header.search": "Search",
  "header.login": "Member Login",
  "hedaer.register": "Register Now",
  "header.create-center": "Creation Center",
  "header.signIn": "Sign",
  "home.view-more": "View More",
  "game-list.category": "Category",
  "game-list.category.more": "More Categories",
  "game-list.tag": "Tag",
  "game-list.tag.more": "More Tags",
  "game-list.filter": "Filters",
  "game-list.filter.clear": "Clear All",
  "game-list.filter.complete": "Filter",
  "virtual-coin.recharge-record": "Recharge Record",
  "virtual-coin.buy-record": "Purchase Record",
  "virtual-coin.check-record": "View Details",
  "virtual-coin.m-coin-tips":
    "You can use M coins to purchase games on this platform, and you can get M coins by recharging. Exchange rate settlement unit: US dollars (real-time exchange rate)",
  "virtual-coin.m-coupon-tips":
    "M Coupons can be used to offset M Coins at a ratio of 1:1 and can be obtained by participating in activities.",
  "virtual-coin.finish": "Complete",
  "virtual-coin.m-coin": "M Coins",
  "virtual-coin.onlinePayment": "Online Payment",
  "virtual-coin.vipCard": "Membership Card",
  "virtual-coin.m-coupon": "M Coupons",
  "virtual-coin.copy.success": "Copy Success",
  "virtual-coin.pay": "Payment",
  "virtual-coin.discount": "Discount",
  "virtual-coin.deduction": "Deduction",
  "virtual-coin.m-coupon.detail": "M Coupon Details",
  "virtual-coin.pay-pop.pay-mode": "Choose Payment Method",
  "virtual-coin.pay-pop.product": "Item",
  "virtual-coin.pay-pop.m-coin": "M Coins",
  "virtual-coin.pay-pop.price": "Price",
  "virtual-coin.pay-pop.confirm": "Confirm Payment",
  "virtual-coin.pay-pop.next": "Next Step",
  "virtual-coin.pay-pop.cancel": "Cancel",
  "virtual-coin.pay-pop.card-no": "Credit Card Number",
  "virtual-coin.pay-pop.card-no.placeholder": "Enter Credit Card Number",
  "virtual-coin.pay-pop.exp-date": "Expiration Date",
  "virtual-coin.pay-pop.exp-date.placeholder": "Enter in the format: 05/2024",
  "virtual-coin.pay-pop.exp-date.validate-tips": "Enter the correct format",
  "virtual-coin.pay-pop.cvv": "Security Code",
  "virtual-coin.pay-pop.cvv.placeholder":
    "Enter the three-digit code on the back of the card",
  "virtual-coin.pay-pop.first-name": "First Name",
  "virtual-coin.pay-pop.first-name.placeholder": "Enter First Name",
  "virtual-coin.pay-pop.last-name": "Last Name",
  "virtual-coin.pay-pop.last-name.placeholder": "Enter Last Name",
  "virtual-coin.pay-pop.address": "Billing Address",
  "virtual-coin.pay-pop.address.placeholder": "Enter Address",
  "virtual-coin.pay-pop.privince": "State/Province",
  "virtual-coin.pay-pop.privince.placeholder": "Enter State/Province",
  "virtual-coin.pay-pop.zip-code": "Zip Code",
  "virtual-coin.pay-pop.zip-code.placeholder": "Enter Zip Code",
  "virtual-coin.pay-pop.country": "Country",
  "virtual-coin.pay-pop.country.placeholder": "Enter Country",
  "virtual-coin.pay-pop.phone": "Phone Number",
  "virtual-coin.pay-pop.phone.placeholder": "Enter Phone Number",
  "virtual-coin.pay-pop.pay.fail": "Payment Failed",
  "virtual-coin.award": "Bonus",
  "virtual-coin.first": "First charge",
  "virtual-coin.date-purchased": "Date Purchased",
  "virtual-coin.pay-pop.mode-list.tips":
    "There is no payment channel at the moment, please try again later",
  "file-upload.btn": "File Upload",
  "account-center.tabs.account": "Account Information",
  "account-center.tabs.collect": "My Favorites",
  "account-center.tabs.like": "My Likes",
  "account-center.tabs.history": "History Records",
  "account-center.tabs.buy": "Purchased Games",
  "account-center.tabs.sub.online": "Online Games",
  "account-center.tabs.sub.offline": "Offline Games",
  "account-center.nickname.label": "Nickname",
  "account-center.nickname.placeholder": "Enter Nickname",
  "account-center.avatar.label": "Avatar",
  "account-center.save.btn": "Save Changes",
  "account-center.email.label": "Email",
  "account-center.phone.label": "Phone Number",
  "account-center.bind.btn": "Click to Bind",
  "account-center.password.label": "Password Setting",
  "account-center.modify-password.btn": "Change Password",
  "account-center.save.success": "Save Success",
  "account-center.id": "User ID",
  "game-card.free": "Free",
  "game-card.discount": "Limited Time Discount",
  "game-card.m-coin": "M Coins",
  "game-card.publicity.wait": "Stay tuned",
  "game-card.publicity.try": "Test in the hot trial",
  "game.price.coupon": "After using the discount",
  "game.price.origin": "Original",
  "game-card.mobileGameGallery": "Mobile Game Gallery",
  "game.toDetail": "Go to Details",
  "game.intro": "Game Introduction",
  "game.announcement": "Update Announcement",
  "game.announcement.more": "View More",
  "game.comment": "All Comments",
  "game.comment.send-success": "Comment Success",
  "game.comment.placeholder": "Enter your comment",
  "game.comment.check-reply": "View Replies",
  "game.comment.hidden-reply": "Hide Replies",
  "game.comment.creator": "Creator",
  "game.comment.top": "Top Comment",
  "game.comment.reply": "Reply",
  "game.comment.more": "View More",
  "game.play.free": "Play for Free Now",
  "game.play": "Play Now",
  "game.play.pc": "Play on PC",
  "game.download": "Download Now",
  "game.download.pop.title": "Download Game",
  "game.download.pop.tips": "Please select your device platform",
  "game.purchased": "You have purchased this game",
  "game.online-tips": "Enjoy full-screen gameplay",
  "game.offline-tips": "Click to download the installation package",
  "game.like": "Like",
  "game.collect": "Collect",
  "game.share": "Share",
  "game.share.copy": "Copy",
  "game.copy.success": "Copy Success",
  "game.info.title": "Game Information",
  "game.info.creatorInfo": "Creator Information",
  "game.info.creator": "Creator",
  "game.info.release-time": "Release Time",
  "game.info.lang": "Supported Languages",
  "game.info.group": "Official Community",
  "game.guess": "Recommended for You",
  "game.activity.countdown": "Countdown",
  "game.buy": "Buy Now",
  "game.buy.m": "M-coin",
  "game.buy.direct": "Purchase",
  "game.buy.direct.btn": "Purchase",
  "game.buy.direct.wait": "You have a purchase order in progress",
  "game.buy.direct.cancel": "Cancel",
  "game.buy.direct.buy.success": "Success",
  "game.buy.direct.buy.success.done": "Done",
  "game.buy.direct.buy.fail": "Fail",
  "game.buy.direct.buy.fail.reason": "Cause of failure",
  "game.buy.direct.buy.fail.done": "Knew",
  "game.buy.direct.buy.loading": "Purchase results query",
  "game.buy.direct.buy.loading.done": "Payment completed",
  "game.buy.model": "Please select the purchase method",
  "game.buy.pop.title": "Buy Game",
  "game.buy.pop.name": "Game Name",
  "game.buy.pop.price": "Amount",
  "game.buy.pop.m-coin": "M Coins",
  "game.buy.pop.m-coupon": "M Coupons",
  "game.buy.pop.discount": "Discount",
  "game.buy.pop.pay": "Payment",
  "game.buy.pop.current-balance": "Current Balance",
  "game.buy.pop.order-detail": "Transaction Overview",
  "game.buy.pop.m-coin-balance": "M Coin Balance",
  "game.buy.pop.m-coupon-balance": "M Coupon Balance",
  "game.buy.pop.no-balance": "Insufficient balance, go to recharge",
  "game.buy.pop.confirm": "Confirm Purchase",
  "game.publicity.wait": "Stay tuned",
  "game.publicity.CompatibleWithAndroidOnly": "Compatible With Android Only",
  "game.publicity.try": "Download Demo",
  "game.publicity.try.start": "Play Demo Online",
  "game.publicity.see.all": "See All",
  "game.preSell.tips": "Officially launched",
  "game.preSell.tips.sub": "Pre-sale",
  "game.preSell.tips.sub.time": "Limited Pre-sale",
  "game.removed": "This game has been removed",
  "game.back": "Back",
  "common.account-center": "Account Center",
  "common.logout": "Logout",
  "common.USD": "USD",
  "login.auth.tips": "Please login first",
  "login.welcome": "Welcome to",
  "login.welcome.title": "MASOBU",
  "login.tab.phone": "Phone Number",
  "login.tab.email": "Email",
  "login.tab.electronicDocument": "Electronic Document",
  "login.form.phone": "Phone Number",
  "login.form.phone.validate": "Enter correct phone number",
  "login.form.phone.placeholder": "Enter Phone Number",
  "login.form.captcha": "Captcha",
  "login.form.captcha.send-success": "Captcha sent successfully",
  "login.form.captcha.send-failed": "Verification code sending failed",
  "login.form.captcha.placeholder": "Enter Captcha",
  "login.form.captcha.send": "Get Captcha",
  "login.form.captcha.image": "Image captcha",
  "login.form.captcha.image.placeholder": "Enter image captcha",
  "login.form.captcha.second": "seconds to re-acquire",
  "login.form.model.password.or": "or",
  "login.form.model.captcha.btn": "Captcha Login",
  "login.form.model.password.btn": "Password Login",
  "login.form.model.password.bind": "Password Bind",
  "login.form.model.captcha.bind": "Captcha Bind",
  "login.form.model.bind.success": "Binding completed",
  "login.form.model.bind.unsuccess": "Binding not completed",
  "login.form.model.back.home": "Back to Home",
  "login.form.model.try.Again": "Try Again",
  "login.form.scan.try.Again": "Verification failed, please try again",
  "login.form.scan.Again": "Re-verification",
  "login.form.inviter.label": "Invitation Code",
  "login.form.inviter.placeholder": "Optional for new users",
  "login.form.password": "Password",
  "login.form.password.new": "New Password",
  "login.form.password.limit": "Enter a password of 8 characters or more",
  "login.form.password.placeholder": "Enter Password",
  "login.form.forgot": "Forgot Password",
  "login.server.tips": "By continuing, you agree to MASOBU's",
  "login.server.tips.sub": "Terms of Service",
  "login.privacy.tips": "and confirm that you have read our",
  "login.privacy.tips.sub": "Privacy Policy",
  "login.btn": "Login",
  "login.page.text": "Login Page",
  "login.btn.register": "Register",
  "login.btn.continue": "Continue",
  "login.btn.confirm": "Confirm",
  "login.phone.register.tips":
    "Unregistered phone numbers will be automatically registered after verification",
  "login.form.email": "Email",
  "login.form.email.placeholder": "Enter Email Address",
  "login.form.email.validate": "Enter correct email",
  "login.form.confirm-password": "Confirm Password",
  "login.form.confirm-password.validate": "Passwords do not match",
  "login.email.register.tips": "Not a member yet?",
  "login.email.register.tips.btn": "Register",
  "login.email.login.tips": "Already have an account?",
  "login.email.login.btn": "Login",
  "login.forgot.save.btn": "Save",
  "login.bind.save.btn": "Confirm",
  "login.modify.mode-select": "Verification Method",
  "login.modify.save.btn": "Save",

  // web-sdk 登陆
  "login.welcome.text": "Welcome to",
  "login.highlight.name": "MASOBU",
  "login.button.existing.account": "Login Existing Account",
  "login.button.play.directly": "Play Directly",
  "login.button.bind.account": "Bind Account",
  "login.button.bind.or": "or",
  "login.button.bind.continue": "continue",
  "login.button.bind.password": "Password Binding",
  "login.button.bind.codekey": "Verification code binding",
  "bind.status.success": "Binding Successful",
  "bind.status.fail": "Binding Failed",
  "bind.button.enter.game": "Enter the game",
  "bind.button.backHome": "Back to Home",
  "bind.button.retry": "Try Again",
  "bind.account.bind.title": "Bind Account",
  "bind.account.bind.tip": "Account binding is required for consumption",
  "bind.account.bind.button.bind": "Bind Account",
  "bind.account.bind.button.login": "Login Existing Account",
  "login.form.phone.again.placeholder": "Please enter your password again",

  "notice.title": "Notice Center",
  "notice.tab.account": "Account Notification",
  "notice.tab.benefits": "Event Benefits",
  "notice.tab.system": "Announcement",
  "notice.no-message": "No messages",
  "notice.more": "View more",
  "notice.date": "Date",
  "age.limit.title": "Are you 18 or older?",
  "age.limit.sub-top":
    "This site contains adult content. Please confirm you are of legal age.",
  "age.limit.sub-bottom":
    "This website contains sexual content and nudity. By clicking “Yes - I am 18 or older” you have read and consent to our policies, guidelines and confirm that you are of legal age of viewing.",
  "age.limit.confirm": "I am 18 years or older",
  "age.limit.cancel": "I am under 18",
  "age.limit.toast":
    "Sorry, users under 18 are not allowed to access this website",
  "inviter.link": "Invitation Link",
  "inviter.code": "Invitation Code",
  "inviter.btn": "Copy",
  "inviter.tips.top":
    "You can share it with friends, who can register directly.",
  "inviter.tips.bottom":
    "Or share the invitation code with friends, who can enter it during registration.",
  "search.close": "Close",
  "search.no-result": "No games",
  "search.title": "Search results",
  "search.placeholder": "Please Enter",
  "task.limit.count.get": "Number of rewards received",
  "task.limit.count": "Remaining rewards",
  "login.modify.save.success": "Successfully modified",
  "game.buy.pop.cancel": "Cancel",
  "game.buy.pop.success": "Purchase Success",
  "game.purchase.not.exist": "This game does not exist",
  "props.title": "Buy",
  "props.name.label": "Props name",
  "props.count.label": "Number of props",
  "props.price.label": "Amount",
  "props.m.coin": "M Coins",
  "props.m.coupons": "M Coupons",
  "props.m.coins.balance": "M Coins balance",
  "props.m.coupons.balance": "M Coupons balance",
  "props.m.coins.pay": "M Coins payment",
  "props.m.coupons.discount": "M Coupons discount",
  "props.m.coupons.discount.limit": "Upper limit",
  "props.m.no.enough": "Insufficient balance, need to recharge",
  "props.buy": "Buy",
  "props.buy.success": "Purchase Success",
  "props.charge": "Go to Recharge",
  "props.tips": "No refunds will be given once virtual goods are sold.",
  "props.no.exist": "The props does not exist",
  "props.limit.left": "No confirmation required for payments below",
  "props.limit.right": "M-coins",
  "activity.time": "Event Time",
  "activity.btn.open": "View more",
  "activity.btn.close": "Close",
  "activity.btn.top": "To Top",
  "account.virtual.m.coupons": "Coupons",
  "coming.soon": "Coming Soon",
  "account.virtual.m.coin": "Coin",
  "account.virtual.m.points": "Point",
  account: "ACCOUNT",
  "layout.header.login": "Play Now",
  "footer.join": "Join the MASOBU community channel",
  "footer.info": "Information",
  "coupon.page.title": "My Coupons",
  "coupon.page.record": "Account Record",
  "coupon.page.memberTitle": "Utilizable Projects",
  "coupon.page.game.tips":
    "Used or expired coupons will disappear after 30 days",
  "coupon.page.btn": "Redeem coupon",
  "coupon.page.coupon.placeholder": "Enter Coupon",
  "coupon.page.tab.valid": "Available coupons",
  "coupon.page.tab.invalid": "Expired coupons",
  "coupon.page.game.title": "Available games",
  "coupon.exchange.confirm": "Confirm",
  "coupon.exchange.cancel": "Cancel",
  "coupon.exchange.know": "Knew",
  "coupon.card.type": "Discount coupons",
  "coupon.card.limit.date": "Valid until",
  "coupon.card.use": "Use",
  "coupon.card.used": "Used",
  "coupon.card.range.all": "All games available",
  "coupon.card.range.part": "Some games available",
  "coupon.card.range.online": "Online games available",
  "coupon.card.range.offline": "Offline games available",
  "coupon.card.range.h5": "H5 games available",
  "coupon.card.expired": "Expired",
  "coupon.use.m.discount": "M coupon discount",
  "coupon.use.coupon": "Coupon",
  "coupon.use.no": "No discounts yet",
  "coupon.select.title": "Coupons available",
  "coupon.select.btn.confirm": "Confirm",
  "coupon.select.btn.cancel": "Cancel",
  "coupon.select.discount": "Deduction",
  "coupon.card.noData": "Currently, there are no available coupons.",
  "vip.card": "Vip Card",
  "vip.card.earnings": "Vip Card Earnings",
  "vip.card.earningsContent": "Unlock monthly card for exclusive benefits",
  "vip.card.couponText": "Coupons available",
  "vip.card.timeLeft": "Time Left: ",
  "vip.card.surplus": "Remaining: ",
  "vip.card.activeSurplus": "Active: Remaining",
  "vip.card.alreadyOwned": "Already Owned",
  "vip.card.day": "Days",
  "vip.card.popupLv": "Aperture Level",
  "vip.card.popupTip": "New Year Special - Upgrade Privileges to 60 Days",
  "vip.card.popupLv01": "Full Member",
  "vip.card.popupLv02": "Junior Member",
  "vip.card.popupLv03": "Intermediate Member",
  "vip.card.popupLv04": "Senior Member",
  "vip.card.popupLabel01": "Free Sexy-Clicks",
  "vip.card.popupQuarterLabel01": "Live Game 90% Off Coupon",
  "vip.card.popupQuarterLabel02": "Gift of {count} M Energy",
  "vip.card.popupQuarterLabel03":
    "Model International Version VIP {count}Coins",
  "vip.card.popupQuarterLabel04": "Dot Series Actresses Unlimited Play",
  "vip.card.popupQuarterLabel05": "Exclusive VPN Membership for {count}Month",
  "vip.card.popupQuarterLabel06": "Bonus M Coins with Recharge",
  "vip.card.popupQuarterLabel07": "Dot Unreleased Catalog Quarterly Gift",
  "vip.card.popupQuarterLabel08": "Adult Game Trial Pioneer",
  "vip.card.popupQuarterLabel09": "Biweekly Gift Single-player 50% Off Coupon",
  "vip.card.popupQuarterLabel10": "Quarterly Gift Model Media Physical Items",
  "vip.card.tag01": "Seductive Points Free Play",
  "vip.card.tag02": "Coupon Pioneer",
  "vip.card.tag03": "M-Coin Bonus",
  "vip.card.tag04": "Receive Physical Gifts",
  "vip.card.tag05": "Trial Play Frontrunner",
  "vip.card.vipLv1": "Bronze",
  "vip.card.vipLv2": "Silver",
  "vip.card.vipLv3": "Gold",
  "vip.card.vipLv4": "Diamond",
  "vip.card.membershipLvExplain": "Membership Levels Explain",
  "vip.card.currentLv": "Current Level",
  "vip.card.privilegedContent": "Privileged Content",
  "vip.card.rebates": "Before reciprocating { count } M coins",
  "vip.card.energy": "Gift {count} M of energy",
  "vip.card.payBtn01": "List Price { listPrice } ",
  "vip.card.payBtn02": "Sale Price { salePrice } / { validDays } days",
  "vip.month": "month",
  "vip.card.masobuEarnings": "Masobu point card {day} days benefits",
  "vip.card.vipExchangeBtn": "Benefits Redemption Steps",
  "vip.card.benefitsRedemptionGuidelines": "Benefits Redemption Guidelines",
  "vip.card.vipExchangePopupTipTitle01":
    "Physical Item Shipping Registration Process",
  "vip.card.vipExchangePopupTipSecondTitle01":
    "1. Fill in Shipping Information",
  "vip.card.vipExchangePopupTipText01":
    "Please copy the template below, complete it fully, and ensure the information is correct to ensure smooth delivery.",
  "vip.card.vipExchangePopupTipSecondTitle02": "Shipping Information Template:",
  "vip.card.vipExchangePopupTipText02":
    "<item> • Masebo UID (Please provide the complete UID displayed on the official website)</item><item>• Recipient's Name (Must match the name on ID card)</item><item>• Mobile Number (Please fill in the correct mobile number for courier contact)</item><item>• Shipping Address (Please provide detailed province/city/district + specific street address)</item><item>• Postal Code (Please fill in the correct area postal code)</item>",
  "vip.card.vipExchangePopupTipSecondTitle03": "2. Return Complete Information",
  "vip.card.vipExchangePopupTipText03":
    " Please contact Masebo official customer service and return complete and correct shipping information to arrange delivery.",
  "vip.card.vipExchangePopupTipSecondTitle04":
    "3. Information Security Guarantee",
  "vip.card.vipExchangePopupTipText04":
    " Masebo promises to protect your personal privacy. All information will only be used for shipping and not for other purposes.",
  "vip.card.vipExchangePopupTipTitle02": "Shipping Information Template:",
  "vip.card.vipExchangePopupTipSecondTitle05":
    "1. Enter the Platform and Register",
  "vip.card.vipExchangePopupTipText05":
    "   Click on M Representative Accelerator / Madou Media International Version to complete member registration.",
  "vip.card.vipExchangePopupTipSecondTitle06":
    "2. Contact Customer Service for Verification",
  "vip.card.vipExchangePopupTipText06":
    "   After registration, please contact Masebo official customer service and provide your registration email.",
  "vip.card.vipExchangePopupTipSecondTitle07": "3. Benefits Distribution",
  "vip.card.vipExchangePopupTipText07":
    "   We will activate your membership benefits or distribute exclusive coins within 5 working days.",
  "vip.card.vipExchangePopupTipSecondTitle08": "4. Notification Confirmation",
  "vip.card.vipExchangePopupTipText08":
    "   After the exchange is completed, you will receive an in-site message notification.",
  "vip.card.vipExchangePopupTipSecondTitle09": "5. Inquiries",
  "vip.card.vipExchangePopupTipText09":
    "   If you have any questions, please contact Masebo official customer service at any time.",
  "vip.card.vipExchangePopupBtn01": "M Representative Accelerator",
  "vip.card.vipExchangePopupBtn02": "Madou Media International Version",
  "vip.card.vipExchangePopupBtn03": "Contact Customer Service",
  "vip.card.masobuSecondEarnings":
    "After purchasing Masa Point Cards, you can unlock M Energy, improve the Sap Point level, and enjoy free gameplay of the entire Sap Point series.",
  "vip.vipPrefecture.lvTip": "Level Description",
  "vip.vipPrefecture.renewalTip": "Masobu Card Renewal",
  "vip.vipPrefecture.renewalPopupTip":
    "Don't worry, your monthly card is still valid! You have [{count}] days remaining. Come to renew it when it expires. We'll always be waiting for you!",
  "vip.vipPrefecture.tab01": "Game Library",
  "vip.vipPrefecture.tab02": "Wish List",
  "vip.vipPrefecture.tab03": "Task List",
  "vip.vipPrefecture.btn01": "Account Credentials",
  "vip.vipPrefecture.btn02": "Switch Account",
  "vip.vipPrefecture.btn03": "Account Recovery",
  "vip.vipPrefecture.boundUp": "Bound Up",
  "vip.vipPrefecture.tipText":
    "Remember to save your credentials to recover your account at any time!",
  "signIn.signAgain": "Sign Again",
  "signIn.energyValue": "Energy",
  "signIn.signInComplete": "Sign In Complete",
  "signIn.title": "Task Panel",
  "signIn.signInRuleText":
    "Check in daily to receive rewards. Spend 5 M-coin to recover each missed day.",
  "signIn.signInRule": "Supplementary Sign In Guidelines and Rules",
  "signIn.claimReward": "Claim Reward",
  "signIn.completed": "Completed",

  "recharge.card.unlock.title": "You've Unlocked Hidden Discount!",
  "recharge.card.unlock.subtitle":
    "Get Free Access to All Series with Card Activation",
  "recharge.card.unlock.price.original": "Original Price ${amount} ",
  "recharge.card.unlock.price.new": "Only ${amount} ",
  "recharge.product.pay.unit": "USD",
  "recharge.card.unlock.chance": "{count} Chances Left",
  "transaction.title": "Transaction Overview",
  "transaction.item.label": "Item",
  "transaction.price.label": "Price",
  "transaction.price.value": "{amount}$",
  "transaction.payment.method": "Select Payment Method",
  "transaction.button.confirm": "Confirm Payment",
  "transaction.button.cancel": "Cancel",
  "transaction.unit": "Monthly Card",

  "recharge.card.title": "Last Chance for Hidden Discount!",
  "recharge.card.unlock.message": "You've Unlocked the Hidden Discount!",
  "recharge.card.price.original": "Original Price {count}{currency}",
  "recharge.card.price.current": "Now Only {count}{currency}",
  "recharge.card.price.combined":
    "Original {originalPrice}{currency} Now Only {currentPrice}{currency}",
  "recharge.card.button.text": "Click to Purchase",
  "recharge.card.benefit.prefix": "Get instant access to",
  "recharge.card.benefit.highlight": "all SeDianDian and LianDianDian games",
  "recharge.card.benefit.suffix": "for free",
  "recharge.card.benefit.tips":
    "*No refunds will be given for virtual goods sold",
  "recharge.card.payment.error": "Payment failed, please try again",
  "recharge.card.payment.selectMethod": "Please select payment method",
  "game.detail.img": "game screen",
  "game.Platformsil.ios": "iOS Only",
  "game.Platformsil.android": "Android Only",
  noVideo: "No promotional video available",
  noDownload: "No download link available for this platform",
  "recharge.bind.secondTitle":
    "Account binding is required to proceed with consumption actions",

  "member.accountCredentials.tips":
    "If your account is lost, please go to [Members area] to recover it, upload or scan the credentials",
  "member.accountCredentials.btn": "Save Account Credentials to Device",
  "member.recoverAccount": "Recover Account",
  "member.recoverAccount.btn01": "Upload Voucher",
  "member.recoverAccount.btn02": "Scan Voucher",
  "qrcode.fetch.failed": "Failed to fetch QR code",
  "image.generation.failed": "Failed to fetch QR code",
  // QR Code Scanning related
  "qrcode.scan.title": "Scan Login",
  "qrcode.scan.guide": "Point at QR code for automatic recognition",
  "qrcode.scan.album": "Album",
  "qrcode.scan.cancel": "Cancel",
  "qrcode.error.device": "Sorry: No scanning device detected!",
  "qrcode.error.camera": "Failed to start camera, please try again",
  "qrcode.error.video": "Video playback error, please try again",
  "qrcode.error.https":
    "To access browser recording functionality, for security reasons, you need to be on localhost, 127.0.0.1, or using https!",
  "qrcode.error.invalid": "Invalid QR code, please try again",
  "qrcode.error.invalid2": "Invalid QR code",
  "qrcode.error.processing": "Image processing failed, please try again",
  "qrcode.error.reading": "File reading failed, please try again",
  "qrcode.error.init":
    "Failed to initialize camera, please refresh and try again",
  "qrcode.error.login": "Login failed, please try again",
  "component.noticeIcon.empty": "No notifications",

  "Recharge.title": "Recharge Account",
  "Recharge.error.general": "Operation failed, please try again later",
  "Recharge.balance.coin": "M Coin Balance",
  "Recharge.balance.coupon": "M Coupon Balance",
  "Recharge.payment.methods": "Select Payment Method",
  "Recharge.payment.status.to_pay": "Waiting for Payment",
  "Recharge.payment.status.in_pay": "Payment Processing",
  "Recharge.payment.status.pay_success": "Payment Successful",
  "Recharge.payment.status.pay_fail": "Payment Failed",
  "Recharge.payment.status.pay_cancel": "Payment Cancelled",
  "Recharge.payment.status.refund": "Refunded",
  "Recharge.payment.success": "Payment Successful!",
  "Recharge.payment.fail": "Payment Failed!",
  "Recharge.product.title": "Product",
  "Recharge.product.price": "Price",
  "Recharge.product.first": "First Recharge Exclusive",
  "Recharge.product.coin": "M Coin",
  "Recharge.product.reward": "Get {amount} M Coupons",
  "Recharge.button.next": "Next",
  "Recharge.button.cancel": "Cancel",
  "Recharge.button.confirm": "Confirm Payment",
  "Recharge.button.close": "Close",
  "Recharge.tips": "*Virtual goods are non-refundable once sold",
  "Recharge.noPayMethod": "No payment method available",

  "GameItemPurchase.propsTitle": "Purchase Items",
  "GameItemPurchase.propsNameLabel": "Item Name",
  "GameItemPurchase.propsCountLabel": "Quantity",
  "GameItemPurchase.propsPriceLabel": "Price",
  "GameItemPurchase.propsMCoinsBalance": "M Coins Balance: ",
  "GameItemPurchase.propsMCouponsBalance": "M Coupons Balance: ",
  "GameItemPurchase.propsMCoinsPay": "Pay with M Coins",
  "GameItemPurchase.propsMCouponsDiscount": "M Coupons Discount",
  "GameItemPurchase.propsMCouponsDiscountLimit": "Maximum Discount",
  "GameItemPurchase.propsMNoEnough": "Insufficient Balance, Need to Recharge",
  "GameItemPurchase.propsMCoin": "M Coin",
  "GameItemPurchase.propsMCoupons": "M Coupon",
  "GameItemPurchase.propsLimitLeft": "Below",
  "GameItemPurchase.propsLimitRight": "No Confirmation for M Coin Payment",
  "GameItemPurchase.propsBuy": "Buy",
  "GameItemPurchase.propsCharge": "Recharge",
  "GameItemPurchase.propsTips": "*Virtual goods are non-refundable once sold",
  "GameItemPurchase.propsBuySuccess": "Purchase Successful",

  rechargeCardTitle: "Last Chance for Hidden Discount!",
  rechargeCardUnlockTitle: "You've Unlocked the Hidden Offer!",
  rechargeCardPriceOriginal: "Original Price: ${amount}",
  rechargeCardPriceNew: "Special Price: ${amount}",
  rechargeCardBenefitPrefix: "Subscribe now to enjoy",
  rechargeCardBenefitHighlight: "all games in the Sedian & Lovian series",
  rechargeCardBenefitSuffix: "free forever",
  rechargeCardButtonText: "Purchase Now",
  rechargeCardImageAlt: "VIP Card Offer",
  rechargeCardNoChannels: "No payment channels available",
  rechargePaySuccessAlt: "Payment Success",
  rechargePaySuccessText: "Completed!",
  rechargePayFailAlt: "Payment Failed",
  rechargePayFailText: "Failed!",

  paymentTitle: "Payment Confirmation",
  paymentProductLabel: "Product",
  paymentPriceLabel: "Price",
  paymentUnit: "VIP Membership Card",
  paymentMethodTitle: "Payment Method",
  paymentMethodAlt: "Pay with {method}",
  paymentConfirmButton: "Confirm Payment",
  paymentCancelButton: "Cancel",
  paymentFooterNote:
    "By proceeding with payment, you agree to the Terms of Service",
  paymentSelectMethodFirst: "Please select a payment method",
  paymentFailMessage: "Payment failed, please try again",
  paymentSystemError: "System error, please try again later",
  paymentProcessing: "Processing payment...",
  paymentSuccess: "Payment Successful!",
  paymentAmount: "Payment Amount",
  paymentOrderNumber: "Order Number",
  paymentTime: "Payment Time",
};
