import { useCallback, useState } from 'react';

// Model 示例，文档：https://umijs.org/docs/max/data-flow
export default function Page() {
  const [hisCarList, setHisCarList] = useState(
    JSON.parse(localStorage.getItem('hisCarList') as string) || [],
  );

  const getHisCarList = () => {
    if (hisCarList.length) return hisCarList;
    setHisCarList(
      JSON.parse(localStorage.getItem('hisCarList') as string) || [],
    );
    return hisCarList;
  };

  const addHisCarNum = (carNum: string) => {
    let list = JSON.parse(localStorage.getItem('hisCarList') as string) || [];
    const findIndex = list.findIndex((item: string) => item === carNum);
    if (~findIndex) {
      list.splice(findIndex, 1);
    }
    list.unshift(carNum);
    // 最多存5个
    if (list.length > 5) {
      list = list.slice(0, 5);
    }
    setHisCarList(list);
    localStorage.setItem('hisCarList', JSON.stringify(list));
  };

  return { hisCarList, getHisCarList, addHisCarNum };
}
