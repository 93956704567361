// @ts-ignore
/* eslint-disable */
import { request } from "umi";

/** 新增游戏发布申请 新增游戏发布申请 POST /c/game/release/apply */
export async function apply1(
  body: API.AddGameReleaseReq,
  options?: { [key: string]: any }
) {
  return request<API.RLong>("/c/game/release/apply", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 撤销游戏发布申请 撤销游戏发布申请 POST /c/game/release/cancel */
export async function cancel(
  body: API.CancelGameReleaseReq,
  options?: { [key: string]: any }
) {
  return request<API.RLong>("/c/game/release/cancel", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 查看游戏发布详情 查看游戏发布详情 GET /c/game/release/get */
export async function get(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getParams,
  options?: { [key: string]: any }
) {
  return request<API.RGetGameReleaseResp>("/c/game/release/get", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询游戏发布列表 查询游戏发布列表 GET /c/game/release/list */
export async function list2(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.list2Params,
  options?: { [key: string]: any }
) {
  return request<API.GetGameReleaseResp>("/c/game/release/list", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 申请游戏下架 申请游戏下架 POST /c/game/removal/apply */
export async function apply(
  body: API.ApplyGameRemovalReq,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/game/removal/apply", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 新增游戏信息 新增游戏信息 POST /c/game/info/add */
export async function add(
  body: API.AddGameInfoReq,
  options?: { [key: string]: any }
) {
  return request<API.RLong>("/c/game/info/add", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询游戏信息列表(创作端) 查询游戏信息列表(创作端) GET /c/game/info/creator/list */
export async function listByCreator(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.listByCreatorParams,
  options?: { [key: string]: any }
) {
  return request<API.TableDataInfoGetGameInfoResp>(
    "/c/game/info/creator/list",
    {
      method: "GET",
      params: {
        ...params,
        req: undefined,
        ...params["req"],
        pageQuery: undefined,
        ...params["pageQuery"],
      },
      ...(options || {}),
    }
  );
}

/** 删除游戏信息：未提交过发布申请 删除游戏信息：未提交过发布申请 POST /c/game/info/delete */
export async function deleteUsingPost(
  body: API.DeleteGameInfoReq,
  options?: { [key: string]: any }
) {
  return request<API.RInteger>("/c/game/info/delete", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 查看游戏详情 查看游戏详情 GET /c/game/info/get */
export async function get1(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.get1Params,
  options?: { [key: string]: any }
) {
  return request<API.RGetGameInfoResp>("/c/game/info/get", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询游戏信息列表(猜你喜欢) 查询游戏信息列表(猜你喜欢) GET /c/game/info/like/list */
export async function listByGuessLike(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.listByGuessLikeParams,
  options?: { [key: string]: any }
) {
  return request<API.GetGuessListListResp>("/c/game/info/like/list", {
    method: "GET",
    params: {
      ...params,
      req: undefined,
      ...params["req"],
    },
    ...(options || {}),
  });
}

/** 查询游戏信息列表(玩家端) 查询游戏信息列表(玩家端) GET /c/game/info/player/list */
export async function listByPlayer(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.listByPlayerParams,
  options?: { [key: string]: any }
) {
  return request<API.TableDataInfoGetGameInfoResp>("/c/game/info/player/list", {
    method: "GET",
    params: {
      ...params,
      req: undefined,
      ...params["req"],
      pageQuery: undefined,
      ...params["pageQuery"],
    },
    ...(options || {}),
  });
}

/** 查询游戏信息列表(玩家端) 查询游戏信息列表(玩家端) GET /c/game/info/player/listByIds */
export async function listByIds(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.listByIdsParams,
  options?: { [key: string]: any }
) {
  return request<API.GetGameInfoResp[]>("/c/game/info/player/listByIds", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新游戏信息 更新游戏信息 POST /c/game/info/update */
export async function update(
  body: API.UpdateGameInfoReq,
  options?: { [key: string]: any }
) {
  return request<API.RLong>("/c/game/info/update", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 新增运营活动 新增运营活动 POST /c/game/activity/add */
export async function add1(
  body: API.AddGameActivityReq,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/game/activity/add", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除运营活动 删除运营活动 POST /c/game/activity/delete */
export async function delete1(
  body: API.DeleteGameActivityReq,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/game/activity/delete", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 根据ID查询运营活动 根据ID查询运营活动 GET /c/game/activity/get */
export async function get2(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.get2Params,
  options?: { [key: string]: any }
) {
  return request<API.RGetGameActivityResp>("/c/game/activity/get", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询运营活动列表 查询运营活动列表 GET /c/game/activity/list */
export async function list3(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.list3Params,
  options?: { [key: string]: any }
) {
  return request<API.TableDataInfoGetGameActivityResp>(
    "/c/game/activity/list",
    {
      method: "GET",
      params: {
        ...params,
        req: undefined,
        ...params["req"],
        pageQuery: undefined,
        ...params["pageQuery"],
      },
      ...(options || {}),
    }
  );
}

/** 更新运营活动 更新运营活动 POST /c/game/activity/update */
export async function update1(
  body: API.UpdateGameActivityReq,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/game/activity/update", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询模板列表 查询模板列表 GET /c/game/template/list */
export async function list1(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.list1Params,
  options?: { [key: string]: any }
) {
  return request<API.TableDataInfoGetGameTemplateResp>(
    "/c/game/template/list",
    {
      method: "GET",
      params: {
        ...params,
        req: undefined,
        ...params["req"],
        pageQuery: undefined,
        ...params["pageQuery"],
      },
      ...(options || {}),
    }
  );
}

/**
 * 查询类别列表
 * @param {string} queryStr
 * @returns
 */
export async function fetchCategoryList(params: API.CategoryListReq = {}) {
  return request<API.CategoryListResp>("/c/category/list", {
    method: "GET",
    params: {
      ...params,
    },
  });
}

/**
 * 查询标签列表
 * @param {string} queryStr
 * @returns
 */
export async function fetchTagList(params: API.TagListReq = {}) {
  return request<API.TagListResp>("/c/tag/list", {
    method: "GET",
    params: {
      ...params,
    },
  });
}

// 根据 id 查询运营活动信息
export async function fetchActivityById(params: API.ActivityInfoReq) {
  return request<API.ActivityInfoResp>("/c/game/activity/getByGameId", {
    method: "GET",
    params,
  });
}

// 游戏购买j价格明细-购买弹窗
export async function getBuyInfo(id: string | number) {
  return request<API.GetBuyInfoResp>(`/c/game/buy/getBuyInfo/${id}`, {
    method: "GET",
  });
}

// sdk游戏购买价格明细-预览页
export async function getBuyInfoBySdk(id: string | number) {
  return request<API.GetBuyInfoResp>(`/c/sdk/game/buy/getBuyInfo/${id}`, {
    method: "GET",
  });
}

// 游戏购买
export async function buyGame(data: API.BuyGameReq) {
  return request("/c/game/buy/buyGame", {
    method: "POST",
    data,
  });
}

// 游戏购买
export async function buyGameBuySdk(data: API.BuyGameReq) {
  return request("/c/sdk/game/buy/buyGame", {
    method: "POST",
    data,
  });
}

// 根据发布ID查询游戏详情
export async function getGameInfoByReleaseId(releaseId: string | number) {
  return request<API.RGetGameInfoResp>("/p/game/release/get", {
    method: "GET",
    params: {
      releaseId,
    },
  });
}

// sdk游戏商品购买价格明细-预览页
export async function getGameProductBuyInfo(
  data: API.GetGameProductBuyInfo4SdkReq
) {
  return request<API.GetGameProductBuyInfo4SdkResp>(
    "/c/sdk/game/getGameProductBuyInfo",
    {
      method: "POST",
      data,
    }
  );
}

// sdk游戏道具购买
export async function buyGameProduct(data: {
  /*游戏id */
  gameId: string;

  /*商品id */
  productId: string;

  /*商品名称 */
  productName: string;

  /*商品数量 */
  productQuantity: number;

  /*商品单价 */
  productPrice: number;

  /*M币支付总金额 */
  aCoinAmount: number;

  /*M券支付总金额 */
  bCoinAmount?: number;
  maxDiscountMCredit?: number | undefined;

  // 给三方的额外参数
  extra?: string;
}) {
  return request("/c/sdk/game/buyGameProduct", {
    method: "POST",
    data,
  });
}

// 搜索游戏
export async function searchGame(params: {
  content: string;
  pageSize: number;
  pageNum: number;
}) {
  return request<API.SearchByPageResp>("/c/game/info/search", {
    method: "GET",
    params,
  });
}

// 游戏直销-购买弹窗
export async function getDirectSaleInfoForSdk(data: API.GetDirectSaleInfoReq) {
  return request<API.GetDirectSaleInfoResp>(
    "/c/sdk/game/buy/getDirectSaleInfo",
    {
      method: "POST",
      data,
    }
  );
}

// 充值并购买
export async function directSaleGameForSdk(data: any) {
  return request<API.DirectSaleGameResp>("/c/sdk/game/buy/directSaleGame", {
    method: "POST",
    data,
  });
}

// 查询直购游戏状态
export async function queryDirectSaleGameStatusForSdk(id: string) {
  return request<API.QueryDirectSaleGameStatusResp>(
    `/c/sdk/game/buy/queryDirectSaleGameStatus/${id}`,
    {
      method: "POST",
    }
  );
}

// 游戏直销-购买弹窗
export async function getDirectSaleInfo(data: API.GetDirectSaleInfoReq) {
  return request<API.GetDirectSaleInfoResp>("/c/game/buy/getDirectSaleInfo", {
    method: "POST",
    data,
  });
}

// 充值并购买
export async function directSaleGame(data: any) {
  return request<API.DirectSaleGameResp>("/c/game/buy/directSaleGame", {
    method: "POST",
    data,
  });
}

// 查询直购游戏状态
export async function queryDirectSaleGameStatus(id: string) {
  return request<API.QueryDirectSaleGameStatusResp>(
    `/c/game/buy/queryDirectSaleGameStatus/${id}`,
    {
      method: "POST",
    }
  );
}

// 获取客服系统地址
export async function getCustomerServiceUrl() {
  return request<{
    code: number;
    msg: string;
    data: string;
  }>("/c/game/info/getCustomerServiceUrl", {
    method: "POST",
    data: {},
  });
}

// 根据类别获取游戏的标签列表
export async function getGameTagsByCategoryId(params: { categoryId: string }) {
  return request<API.GetGameTagsByCategoryIdResp>(
    `/c/game/info/player/getTagListByCategory`,
    {
      method: "GET",
      params,
    }
  );
}

// 查询专题及游戏列表
export async function getCategoryOrList(params: { categoryId?: string }) {
  return request(`/c/game/info/byCategory`, {
    method: "GET",
    params,
  });
}
export async function getSdkGameDetail(id: string) {
  return request(`/c/sdk/game/detail/${id}`, {
    method: "GET",
  });
}

export async function queryBuyGameProductRecords(id: string) {
  return request(`/c/sdk/game/queryBuyGameProductRecords/${id}`, {
    method: "GET",
    params: {
      sign: true,
    },
  });
}

export async function queryConfig(gameId: string) {
  return request(`/c/game/developer/edit/getConfig`, {
    method: "GET",
    params: {
      editGameId: gameId,
    },
  });
}

export async function queryBuyVipCardRecords(id: string) {
  return request(`/c/sdk/game/queryVipStatus/${id}`, {
    method: "GET",
    params: {
      sign: true,
    },
  });
}

// sdk获取用户是否满足弹出月卡弹窗条件
export async function queryBuyProductCountInfo(id: string) {
  return request(`/c/sdk/game/sdd/buyProductCountInfo`, {
    method: "GET",
    params: {
      gameId: id,
    },
  });
}

// sdk获取月卡价格信息
export async function getVipProductInfo(id: string) {
  return request(`/c/sdk/game/sdd/getVipProductInfo`, {
    method: "GET",
    params: {
      gameId: id,
    },
  });
}
