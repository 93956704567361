import moment from "moment";
import avgHandler from "./avgHandler";

export function getQueryStringByName(params: string, name: string) {
  var result = params.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));
  if (result == null || result.length < 1) {
    return "";
  }
  return result[1];
}

/**
 * 车牌号码格式化
 * @param carNum - 车牌
 */
export function formatCarNum(carNum: string) {
  let strArr = carNum.split("");
  strArr.splice(2, 0, "·");
  return strArr.join("");
}

export function toHoursAndMinutes(totalMinutes: any) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
}

// 单位转换 px => vw
export function PXToVW(px: number, refer = 375) {
  const result = (px * 100) / refer;

  return `${parseFloat(result.toFixed(5))}vw`;
}

// 获取操作系统
export function getMobileOperatingSystem() {
  const userAgent = window.navigator.userAgent;
  const _userAgent = userAgent.toLowerCase();

  if (_userAgent.match(/ipad/i) || _userAgent.match(/iphone/i)) {
    return "h5_ios";
  } else if (_userAgent.match(/android/i)) {
    return "h5_android";
  } else {
    return "h5_pc";
  }
}

// sdk运行环境
export function getSDKMobileOperatingSystem() {
  // sysType是否包含['sdk_ios','sdk_android','sdk_windows','sdk_mac']
  const isCludeSdkType = [
    "sdk_ios",
    "sdk_android",
    "sdk_windows",
    "sdk_mac",
  ].includes(avgHandler.getSysType());
  if (isCludeSdkType) {
    return avgHandler.getSysType();
  }
  const userAgent = window.navigator.userAgent.toLowerCase();

  // 使用正则表达式一次性测试多个条件
  if (/ipad|iphone|ipod/i.test(userAgent)) {
    return "sdk_ios";
  } else if (/android/i.test(userAgent)) {
    return "sdk_android";
  } else if (/win/i.test(userAgent)) {
    return "sdk_windows";
  } else if (/macintosh|mac os x/i.test(userAgent)) {
    return "sdk_mac";
  } else if (/linux/i.test(userAgent)) {
    return "sdk_linux";
  } else {
    return "h5_pc"; // 默认返回
  }
}

const fpPromise = import("@/utils/fingerprint.js").then((FingerprintJS) =>
  FingerprintJS.load()
);
export async function generateUUID() {
  try {
    const result = await fpPromise.then((fp) => fp.get());
    const visitorId = result.visitorId;
    sessionStorage.setItem("visitorId", visitorId);

    return visitorId;
  } catch (error) {
    console.error("Failed to generate UUID:", error);
    throw new Error("Failed to generate UUID");
  }
}

// 根据系统类型区分PC和移动端
export function getSystemType(val: string) {
  if (
    val === "cli_macos" ||
    val === "cli_win" ||
    val === "pc_macos" ||
    val === "pc_windows" ||
    val === "h5_pc"
  ) {
    return "pc";
  } else {
    return "h5";
  }
}

export function convertServerTimeToLocal(time: string) {
  const offset = new Date().getTimezoneOffset();
  const serverDate = new Date(time).getTime();
  const ddDate = (-offset / 60 - 8) * 60 * 60 * 1000 + serverDate;
  const date = moment(new Date(ddDate));
  return date;
}

/**
 * 节流函数
 * @param {Function} fn - 需要节流的函数
 * @param {number} interval - 节流时间间隔(毫秒)
 * @returns {Function} - 返回节流后的函数
 */
export function throttle(
  fn: (paymentTradeNo: string) => void,
  interval: number
) {
  let lastTime = 0;

  const _throttle = function (paymentTradeNo: string) {
    const nowTime = Date.now();
    const realTime = interval - (nowTime - lastTime);

    if (realTime <= 0) {
      fn(paymentTradeNo);
      lastTime = nowTime;
    }
  };

  return _throttle;
}

export const isEmptyExceptString = (value: any) => {
  // 如果是空字符串，返回 false（因为我们不把空字符串视为空值）
  if (value === "") {
    return true;
  }
  // 检查 null 和 undefined
  if (value === null || value === undefined) {
    return false;
  }
  // 检查 NaN
  if (typeof value === "number" && isNaN(value)) {
    return false;
  }
  // 检查空数组
  if (Array.isArray(value) && value.length === 0) {
    return false;
  }
  // 检查空对象 (不包含任何自有属性)
  if (typeof value === "object" && Object.keys(value).length === 0) {
    return false;
  }
  // 其他情况都不是空值
  return true;
};

/**
 * 向URL添加ORIGIN参数
 * @param {string} url - 原始URL
 * @param {string} originValue - ORIGIN参数的值
 * @return {string} 添加ORIGIN参数后的URL
 */
export function appendOriginParam(url: string, originValue: string) {
  // 检查URL是否有效
  if (!url || typeof url !== "string") {
    console.error("无效的URL");
    return url;
  }

  // 检查originValue是否有效
  if (!originValue) {
    console.error("无效的ORIGIN值");
    return url;
  }

  // 创建URL对象以便于处理
  try {
    const urlObj = new URL(url);

    // 检查URL是否已经包含查询参数
    if (urlObj.search) {
      // 已有查询参数，使用&拼接
      return `${url}&ORIGIN=${originValue}`;
    } else {
      // 没有查询参数，使用？拼接
      return `${url}?ORIGIN=${originValue}`;
    }
  } catch (error) {
    console.error("URL解析错误：", error);

    // 如果URL对象创建失败，使用字符串方法处理
    if (url.includes("?")) {
      // 已有查询参数，使用&拼接
      return `${url}&ORIGIN=${originValue}`;
    } else {
      // 没有查询参数，使用？拼接
      return `${url}?ORIGIN=${originValue}`;
    }
  }
}

/**
 * 从URL查询字符串中提取完整的game参数值（包含所有嵌套参数）
 * @returns {string|null} 完整的game参数值，如果不存在则返回null
 */
export function getFullGameUrl() {
  // 获取完整的查询字符串
  const queryString = window.location.search;

  // 如果查询字符串为空，则返回null
  if (!queryString || queryString.length <= 1) return null;

  // 移除开头的问号
  const queryWithoutQuestion = queryString.substring(1);

  // 查找game=的位置
  const gamePrefix = "game=";
  const gameIndex = queryWithoutQuestion.indexOf(gamePrefix);

  // 如果没有找到game参数，则返回null
  if (gameIndex === -1) return null;

  // 提取game=后面的所有内容
  const fullGameValue = queryWithoutQuestion.substring(
    gameIndex + gamePrefix.length
  );
  // 解码URL
  return decodeURIComponent(fullGameValue);
}
