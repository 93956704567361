import { request } from "umi";

// sdk调用支付月卡接口
export async function rechargeVipCard(payParams: {
  gameId: string;
  rchgFor: string;
  rchgType: string;
  sourceType: number;
  vipCardId: string | number;
}) {
  return request("/c/sdk/game/sdd/buyVipCard", {
    method: "POST",
    data: payParams,
  });
}

// 查询支付结果
export async function getRechargeResult(params: any) {
  return request("/c/product/getRechargeResult", {
    method: "GET",
    params,
  });
}
