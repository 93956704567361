// window.AvgHandler = {
//   isSDK: () => true,
//   closePage: () => 1,
//   getToken: () => '',
//   getGameId: () => 'EG180158134179660147',
//   getSysType: () => 1,
//   getPropId: () => 1,
//   getPropName: () => '测试道具',
//   getPropNum: () => 20,
//   getPropPrice: () => 100000,
//   getDeviceId: () => 1,
//   getDeviceType: () => '1',
//   getLanguage: () => 'zh_CN',
//   buyGameProductComplete: () => {console.log('success', 1)},
//   buyGameBuySdkComplete: () => {console.log('success', 2)}
// }

// npm install --force
// 部署 npm run deploy:test
// npm run deploy:pre
const log_avgInfo = false;

/**
 * 适配在 app 下 window.AvgHandler 传入数据
 * 暂时使用
 */
function _trygetAvgHandler() {
  var searchParams: string[] = window.location.search?.substring(1).split('&');
  if (searchParams != null && searchParams.length > 0 && searchParams[0] != "") {
    var params: any = {};
    for (const param of searchParams) {
      const [key, value] = param.split('=');
      if (key == "proName") {
        params[key] = decodeURIComponent(value);
      } else {
        params[key] = value;
      }

    }


    if (params.useAvgHandler == "true") {

      if (params.isPC == "true") {

        window.AvgHandler = {
          isSDK: () => true,
          getToken: () => { if (log_avgInfo) console.log('token ' + params.token); return params.token },
          getGameId: () => { if (log_avgInfo) console.log('gameId ' + params.gameId); return params.gameId },
          getSysType: () => { if (log_avgInfo) console.log('sysType ' + params.sysType); return params.sysType },
          isFreeCheckSmallPlay: () => { if (log_avgInfo) console.log('isFreeCheckSmallPlay ' + params.isFreeCheckSmallPlay); return params.isFreeCheckSmallPlay },
          getPropId: () => { if (log_avgInfo) console.log('proId ' + params.proId); return params.proId },
          getPropName: () => { if (log_avgInfo) console.log('proName ' + params.proName); return params.proName },
          getPropNum: () => { if (log_avgInfo) console.log('proNum ' + params.proNum); return params.proNum },
          getPropPrice: () => { if (log_avgInfo) console.log('proPrice ' + params.proPrice); return params.proPrice },
          getPropPrice_MQ: () => { if (log_avgInfo) console.log('proPrice_MQ ' + params.proPrice_MQ); return params.proPrice_MQ },
          getDeviceId: () => { if (log_avgInfo) console.log('deviceId ' + params.deviceId); return params.deviceId },
          getDeviceType: () => { if (log_avgInfo) console.log('deviceType ' + params.deviceType); return params.deviceType },
          getLanguage: () => { if (log_avgInfo) console.log('language ' + params.language); return params.language },
          getExtra: () => { if (log_avgInfo) console.log('extra ' + params.extra); return params.extra }, // 添加extra

          closePage: () => {
            console.log("__closePage__");
          },

          /**
       * 购买道具完成回调
       * @param success 是否购买成功
       * @param productId  购买 物品id
       * @param productName 道具名
       * @param productQuantity 道具数量
       * @param productPrice 道具价格 （需要的M币）
       * @param aCoinAmount  所需M币数量
       * @param bCoinAmount  最大可抵扣M券数量
       */
          buyGameProductComplete: (success: boolean,
            productId: string,
            productName: string,
            productQuantity: string,
            productPrice: number,
            aCoinAmount: number,
            bCoinAmount: number,
            freeCheckSmallPlay: boolean) => {

            let url: string = "__buyGameProduct_complete__?success=" + success +
              "&productId=" + productId +
              "&productName=" + productName +
              "&productQuantity=" + productQuantity +
              "&productPrice=" + productPrice +
              "&aCoinAmount=" + aCoinAmount +
              "&bCoinAmount=" + bCoinAmount +
              "&freeCheckSmallPlay=" + freeCheckSmallPlay;

            console.log(url);
          },
          buyGameBuySdkComplete: (success: boolean) => {
            console.log("__buyGameBuySdk_complete__?success=" + success);
          },
          openLoginPage: () => {
            console.log("__openLoginPage__");
          },
          message_error: (msg: string) => {
            console.log("__message_error__?msg=" + msg);
          },
          setFreeCheckSmallPlay: (v: boolean) => {
            console.log("__setFreeCheckSmallPlay__?value=" + v);
          },
          loginComplete: (token: string, msg: string) => {
            console.log("__loginComplete__?token=" + token + "&msg=" + msg);
          }
        }

      } else {

        window.AvgHandler = {
          isSDK: () => true,
          getToken: () => { if (log_avgInfo) console.log('token ' + params.token); return params.token },
          getGameId: () => { if (log_avgInfo) console.log('gameId ' + params.gameId); return params.gameId },
          getSysType: () => { if (log_avgInfo) console.log('sysType ' + params.sysType); return params.sysType },
          isFreeCheckSmallPlay: () => { if (log_avgInfo) console.log('isFreeCheckSmallPlay ' + params.isFreeCheckSmallPlay); return params.isFreeCheckSmallPlay },
          getPropId: () => { if (log_avgInfo) console.log('proId ' + params.proId); return params.proId },
          getPropName: () => { if (log_avgInfo) console.log('proName ' + params.proName); return params.proName },
          getPropNum: () => { if (log_avgInfo) console.log('proNum ' + params.proNum); return params.proNum },
          getPropPrice: () => { if (log_avgInfo) console.log('proPrice ' + params.proPrice); return params.proPrice },
          getPropPrice_MQ: () => { if (log_avgInfo) console.log('proPrice_MQ ' + params.proPrice_MQ); return params.proPrice_MQ },
          getDeviceId: () => { if (log_avgInfo) console.log('deviceId ' + params.deviceId); return params.deviceId },
          getDeviceType: () => { if (log_avgInfo) console.log('deviceType ' + params.deviceType); return params.deviceType },
          getLanguage: () => { if (log_avgInfo) console.log('language ' + params.language); return params.language },
          getExtra: () => { if (log_avgInfo) console.log('extra ' + params.extra); return params.extra }, // 添加extra

          closePage: () => {
            window.location.href = "uniwebview://__closePage__";
          },


          /**
         * 购买道具完成回调
         * @param success 是否购买成功
         * @param productId  购买 物品id
         * @param productName 道具名
         * @param productQuantity 道具数量
         * @param productPrice 道具价格 （需要的M币）
         * @param aCoinAmount  所需M币数量
         * @param bCoinAmount  最大可抵扣M券数量
         */
          buyGameProductComplete: (
            success: boolean,
            productId: string,
            productName: string,
            productQuantity: string,
            productPrice: number,
            aCoinAmount: number,
            bCoinAmount: number,
            freeCheckSmallPlay: boolean) => {
            let url: string = "uniwebview://__buyGameProduct_complete__?success=" + success +
              "&productId=" + productId +
              "&productName=" + productName +
              "&productQuantity=" + productQuantity +
              "&productPrice=" + productPrice +
              "&aCoinAmount=" + aCoinAmount +
              "&bCoinAmount=" + bCoinAmount +
              "&freeCheckSmallPlay=" + freeCheckSmallPlay;

            window.location.href = url;
          },
          buyGameBuySdkComplete: (success: boolean) => {
            window.location.href = "uniwebview://__buyGameBuySdk_complete__?success=" + success;
          },
          openLoginPage: () => {
            window.location.href = "uniwebview://__openLoginPage__";
          },
          message_error: (msg: string) => {
            // 过滤掉
          },
          setFreeCheckSmallPlay: (v: boolean) => {
            window.location.href = "uniwebview://__setFreeCheckSmallPlay__?value=" + v;
          },
          loginComplete: (token: string, msg: string) => {
            window.location.href = "uniwebview://__loginComplete__?token=" + token + "&msg=" + msg;
          }
        }

      }









    }
  }

}



// 判断环境是否为 sdk
const isSDK = () => {

  // 尝试获取地址栏的数据
  if (window.AvgHandler == null) {
    _trygetAvgHandler();
  }

  return !!window.AvgHandler
}


// 关闭页面
const closePage = () => {
  window.AvgHandler.closePage()
}

// 获取 token
const getToken = () => {
  const token = window.AvgHandler.getToken()
  return token
}

// 获取游戏 id
const getGameId = () => {
  const gameId = window.AvgHandler.getGameId()
  return gameId
}


/**
 * 购买道具完成回调
 * @param success 是否购买成功
 * @param productId  购买 物品id
 * @param productName 道具名
 * @param productQuantity 道具数量
 * @param productPrice 道具价格 （需要的M币）
 * @param aCoinAmount  所需M币数量
 * @param bCoinAmount  最大可抵扣M券数量
 */
const buyGameProductComplete = (
  success: boolean,
  productId: string,
  productName: string,
  productQuantity: number,
  productPrice: number,
  aCoinAmount: number,
  bCoinAmount: number,
  freeCheckSmallPlay: boolean) => {
  if (window.AvgHandler.buyGameProductComplete != null) {
    window.AvgHandler.buyGameProductComplete(success, productId, productName, productQuantity, productPrice, aCoinAmount, bCoinAmount, freeCheckSmallPlay)
  }
}

/**
 * 购买游戏完成回调
 * @param success 是否购买成功
 */
const buyGameBuySdkComplete = (success: boolean) => {
  if (window.AvgHandler.buyGameBuySdkComplete != null) {
    window.AvgHandler.buyGameBuySdkComplete(success)
  }
}


// 获取支付类型
const getSysType = () => {
  const sysType = window.AvgHandler.getSysType()
  return sysType
}

/**
 * 获取是否选中 小额免确认弹窗 
 * @returns 
 */
const isFreeCheckSmallPlay = () => {
  const v = window.AvgHandler.isFreeCheckSmallPlay()
  if (v == "true") {
    return true;
  }
  return false
}


// 获取道具 id
const getPropId = () => {
  const propId = window.AvgHandler.getPropId()
  return propId
}

// 获取道具名称
const getPropName = () => {
  const propName = window.AvgHandler.getPropName()
  return propName
}

// 获取道具数量
const getPropNum = () => {
  const propNum = window.AvgHandler.getPropNum()
  return propNum
}

// 获取道具价格(M币)
const getPropPrice = () => {
  const propPrice = window.AvgHandler.getPropPrice()
  return propPrice
}

// 获取道具价格(M券)
const getPropPrice_MQ = () => {
  const propPrice = window.AvgHandler.getPropPrice_MQ()
  return propPrice
}

// 获取设备 id
const getDeviceId = () => {
  const deviceId = window.AvgHandler.getDeviceId()
  return deviceId
}

// 获取extra参数
const getExtra = () => {
  const extra = window.AvgHandler.getExtra()
  return extra
}

// 获取设备类型
const getDeviceType = () => {
  const deviceType = window.AvgHandler.getDeviceType()
  return deviceType
}

// 获取语言
const getLanguage = () => {
  const language = window.AvgHandler.getLanguage()
  return language
}

// 跳转到登录页
const openLoginPage = () => {
  window.AvgHandler.openLoginPage()
}

// 在PC平台中，message.error 好像无效，所有添加 window.AvgHandler.message_error 通知 SDK 显示提示
const message_error = (msg: string) => {
  if (window.AvgHandler != null && window.AvgHandler.message_error != null) {
    window.AvgHandler.message_error(msg);
  }
}

/**
 * 设置是否使用 免小额免确认支付
 * @param v 
 */
const setFreeCheckSmallPlay = (v: boolean) => {
  if (window.AvgHandler.setFreeCheckSmallPlay != null) {
    window.AvgHandler.setFreeCheckSmallPlay(v)
  }
}

/**
 * 登录完成回调
 * @param token 如果登录成功，则返回 token，否则返回空字符串
 * @param msg  如果登录成功，msg=="success",如果登录失败，msg 是错误信息
 */
const loginComplete = (token: string, msg?: string) => {
  if (window.AvgHandler.loginComplete != null) {
    window.AvgHandler.loginComplete(token, msg)
  }
}



export default {
  isSDK,
  closePage,
  getToken,
  getGameId,
  buyGameProductComplete,
  buyGameBuySdkComplete,
  getSysType,
  isFreeCheckSmallPlay,
  getPropId,
  getPropName,
  getPropNum,
  getPropPrice,
  getPropPrice_MQ,
  getExtra,
  getDeviceId,
  getDeviceType,
  getLanguage,
  openLoginPage,
  message_error,
  setFreeCheckSmallPlay,
  loginComplete
}
