export default {
  // 菜单
  "tab.menu": "菜单",
  "tab.myAccount": "我的账号",
  "tab.signIn": "登录",
  "tab.hall": "游戏大厅",
  "tab.task": "任务",
  "tab.pay": "充值中心",
  "tab.slide": "滑动",
  "menu.home": "首页",
  "menu.redeem-codes": "兑换代码",
  "menu.online-game": "在线游戏",
  "menu.offline-game": "游戏一览",
  "menu.h5-game": "H5游戏",
  "menu.virtual-coin": "充值中心",
  "menu.game-detail": "游戏详情",
  "menu.game-auditing": "游戏审核",
  "menu.account-center": "个人中心",
  "menu.age-limit": "年龄限制",
  "menu.privacy": "隐私权政策",
  "menu.server": "服务条款",
  "menu.search": "搜索",
  "menu.activity": "活动中心",
  "menu.signUp": "登出",
  "menu.currentDevice": "當前登錄設備",
  "menu.vipPrefecture": "会员专区",
  "menu.lang": "语言",
  "menu.toUp": "我要充值",
  "menu.payVip": "续订点卡",
  "menu.energy": "能量",
  "menu.mEnergy": "M能量",
  "menu.payVipCard": "购买会员卡",
  "menu.payVipCardTip": "解锁，享更多返利优惠!",
  "menu.bindAccount": "绑定帐号",
  "menu.loginAccount": "登入现有帐号",
  "lang.cancel": "取消",
  // 导航栏
  "header.search": "搜索",
  "header.login": "会员登录",
  "hedaer.register": "立即注册",
  "header.create-center": "创作中心",
  "header.signIn": "签到",
  // 首页
  "home.view-more": "观看更多",
  // 游戏列表（在线游戏/下载游戏通用）
  "game-list.category": "类别",
  "game-list.category.more": "更多类别",
  "game-list.tag": "标签",
  "game-list.tag.more": "更多标签",
  "game-list.filter": "过滤器",
  "game-list.filter.clear": "清除全部",
  "game-list.filter.complete": "筛选",
  // 虚拟币
  "virtual-coin.recharge-record": "充值记录",
  "virtual-coin.buy-record": "购买记录",
  "virtual-coin.check-record": "查看明细",
  "virtual-coin.m-coin-tips":
    "您可以使用M币购买本平台的游戏，可通过充值获得M币。汇率结算单位：美元（实时汇率）",
  "virtual-coin.m-coupon-tips":
    "M券可用于抵扣M币，比例为1:1，可通过参与活动获得",
  "virtual-coin.finish": "立即完成",
  "virtual-coin.m-coin": "M币",
  "virtual-coin.onlinePayment": "线上支付",
  "virtual-coin.vipCard": "权益卡",
  "virtual-coin.m-coupon": "M券",
  "virtual-coin.copy.success": "复制成功",
  "virtual-coin.pay": "支付",
  "virtual-coin.discount": "优惠",
  "virtual-coin.deduction": "抵扣",
  "virtual-coin.m-coupon.detail": "M券明细",
  "virtual-coin.pay-pop.pay-mode": "选择支付方式",
  "virtual-coin.pay-pop.product": "项目",
  "virtual-coin.pay-pop.m-coin": "M币",
  "virtual-coin.pay-pop.price": "价格",
  "virtual-coin.pay-pop.confirm": "确认支付",
  "virtual-coin.pay-pop.next": "下一步",
  "virtual-coin.pay-pop.cancel": "取消",
  "virtual-coin.pay-pop.card-no": "信用卡卡号",
  "virtual-coin.pay-pop.card-no.placeholder": "请输入信用卡卡号",
  "virtual-coin.pay-pop.exp-date": "有效期限",
  "virtual-coin.pay-pop.exp-date.placeholder": "按格式输入：05/2024",
  "virtual-coin.pay-pop.exp-date.validate-tips": "请输入正确的格式",
  "virtual-coin.pay-pop.cvv": "安全码",
  "virtual-coin.pay-pop.cvv.placeholder": "输入卡背后三位数",
  "virtual-coin.pay-pop.first-name": "名字",
  "virtual-coin.pay-pop.first-name.placeholder": "输入名",
  "virtual-coin.pay-pop.last-name": "姓氏",
  "virtual-coin.pay-pop.last-name.placeholder": "输入姓",
  "virtual-coin.pay-pop.address": "账单地址",
  "virtual-coin.pay-pop.address.placeholder": "输入地址",
  "virtual-coin.pay-pop.privince": "州/省",
  "virtual-coin.pay-pop.privince.placeholder": "输入州省",
  "virtual-coin.pay-pop.zip-code": "邮递区号",
  "virtual-coin.pay-pop.zip-code.placeholder": "输入邮递区号",
  "virtual-coin.pay-pop.country": "国家",
  "virtual-coin.pay-pop.country.placeholder": "输入国家",
  "virtual-coin.pay-pop.phone": "手机号码",
  "virtual-coin.pay-pop.phone.placeholder": "输入手机号码",
  "virtual-coin.pay-pop.pay.fail": "支付失败",
  "virtual-coin.award": "加赠",
  "virtual-coin.first": "首充专享",
  "virtual-coin.date-purchased": "购买日期",

  // 新增
  "virtual-coin.pay-pop.mode-list.tips": "暂无支付渠道，请稍后再试",
  // 文件上传组件
  "file-upload.btn": "文件上传",
  // 个人中心
  "account-center.tabs.account": "账户信息",
  "account-center.tabs.collect": "我的收藏",
  "account-center.tabs.like": "我的点赞",
  "account-center.tabs.history": "历史记录",
  "account-center.tabs.buy": "已购游戏",
  "account-center.tabs.sub.online": "在线游戏",
  "account-center.tabs.sub.offline": "下载游戏",
  "account-center.nickname.label": "昵称",
  "account-center.nickname.placeholder": "请输入昵称",
  "account-center.avatar.label": "个人头像",
  "account-center.id": "用户ID",
  "account-center.save.btn": "保存修改",
  "account-center.email.label": "电子邮箱",
  "account-center.phone.label": "手机号码",
  "account-center.bind.btn": "点击绑定",
  "account-center.password.label": "密码设定",
  "account-center.modify-password.btn": "重设密码",
  "account-center.save.success": "保存成功",
  // 游戏卡片
  "game-card.free": "免费",
  "game-card.discount": "限时优惠",
  "game-card.m-coin": "M币",
  "game-card.publicity.wait": "敬请期待",
  "game-card.publicity.try": "火热试玩中",
  "game.price.coupon": "使用优惠后",
  "game.price.origin": "原价",
  "game-card.mobileGameGallery": "手游馆",
  // 游戏详情
  "game.toDetail": "前往详情",
  "game.intro": "游戏介绍",
  "game.announcement": "更新公告",
  "game.announcement.more": "查看更多",
  "game.comment": "全部评论",
  "game.comment.send-success": "评论成功",
  "game.comment.placeholder": "请输入你的评论",
  "game.comment.check-reply": "查看回覆",
  "game.comment.hidden-reply": "隐藏回覆",
  "game.comment.creator": "作者",
  "game.comment.top": "置顶评论",
  "game.comment.reply": "回覆",
  "game.comment.more": "查看更多",
  "game.play.free": "立即免费玩",
  "game.play": "立即玩",
  "game.play.pc": "前往PC游玩",
  "game.download": "立即下载",
  "game.download.pop.title": "下载游戏",
  "game.download.pop.tips": "请选择您的装置平台",
  "game.purchased": "你已购买此游戏",
  "game.online-tips": "享受全屏幕游玩",
  "game.offline-tips": "点击下载安装包",
  "game.like": "点赞",
  "game.collect": "收藏",
  "game.share": "分享",
  "game.share.copy": "点击复制",
  "game.copy.success": "复制成功",
  "game.info.title": "游戏信息",
  "game.info.creatorInfo": "创作者信息",
  "game.info.creator": "创作者",
  "game.info.release-time": "上线时间",
  "game.info.lang": "支持语言",
  "game.info.group": "官方社群",
  "game.guess": "猜你喜欢",
  "game.activity.countdown": "倒计时",
  "game.buy": "立即购买",
  "game.buy.m": "M币购买",
  "game.buy.direct": "直接购买",
  "game.buy.direct.btn": "确定购买",
  "game.buy.direct.wait": "您有在支付中的购买订单",
  "game.buy.direct.cancel": "取消",
  "game.buy.direct.buy.success": "购买成功",
  "game.buy.direct.buy.success.done": "完成",
  "game.buy.direct.buy.fail": "购买失败",
  "game.buy.direct.buy.fail.reason": "失败原因",
  "game.buy.direct.buy.fail.done": "知道了",
  "game.buy.direct.buy.loading": "购买结果查询中",
  "game.buy.direct.buy.loading.done": "已完成支付",
  "game.buy.model": "请选择购买方式",
  "game.buy.pop.title": "购买游戏",
  "game.buy.pop.name": "游戏名称",
  "game.buy.pop.price": "金额",
  "game.buy.pop.m-coin": "M币",
  "game.buy.pop.m-coupon": "M券",
  "game.buy.pop.discount": "优惠",
  "game.buy.pop.pay": "支付",
  "game.buy.pop.current-balance": "当前余额",
  "game.buy.pop.order-detail": "交易概览",
  "game.buy.pop.m-coin-balance": "M币余额",
  "game.buy.pop.m-coupon-balance": "M券余额",
  "game.buy.pop.no-balance": "余额不足，去充值",
  "game.buy.pop.confirm": "确认购买",
  "game.publicity.wait": "敬请期待",
  "game.publicity.CompatibleWithAndroidOnly": "仅支持Android系统",
  "game.publicity.try": "下载试玩版",
  "game.publicity.try.start": "开始试玩",
  "game.publicity.see.all": "查看全部",
  "game.preSell.tips": "正式上线",
  "game.preSell.tips.sub": "预售中",
  "game.preSell.tips.sub.time": "限时预售中",
  "game.removed": "该游戏已下架",
  "game.back": "返回",
  // 新增
  "game.buy.pop.cancel": "取消",
  "game.buy.pop.success": "购买成功",
  // 公共部分
  "common.account-center": "个人中心",
  "common.logout": "退出登录",
  "common.USD": "美金",
  // 登录弹窗
  "login.auth.tips": "请先登录",
  "login.welcome": "欢迎来到",
  "login.welcome.title": "麻涩部",
  "login.tab.phone": "手机号码",
  "login.tab.email": "电子邮箱",
  "login.tab.electronicDocument": "电子凭证",
  "login.form.phone": "手机号码",
  "login.form.phone.validate": "请输入正确的手机号",
  "login.form.phone.placeholder": "请输入手机号码",
  "login.form.captcha": "验证码",
  "login.form.captcha.send-success": "验证码发送成功",
  "login.form.captcha.send-failed": "验证码发送失败",
  "login.form.captcha.placeholder": "请输入验证码",
  "login.form.captcha.send": "取得验证码",
  "login.form.captcha.image": "图片验证码",
  "login.form.captcha.image.placeholder": "请输入图形验证码",
  "login.form.captcha.second": "秒后重新获取",
  "login.form.model.password.or": "或",
  "login.form.model.captcha.btn": "验证码登入",
  "login.form.model.password.btn": "密码登入",
  "login.form.model.password.bind": "密码绑定",
  "login.form.model.captcha.bind": "验证码绑定",
  "login.form.model.bind.success": "绑定完成",
  "login.form.model.bind.unsuccess": "绑定未完成",
  "login.form.model.back.home": "返回首页",
  "login.form.model.try.Again": "再次尝试",
  "login.form.scan.try.Again": "验证失败，请重新尝试",
  "login.form.scan.Again": "重新驗證",
  "login.form.inviter.label": "邀请码",
  "login.form.inviter.placeholder": "新用户选填",
  "login.form.password": "密码",
  "login.form.password.new": "新密码",
  "login.form.password.limit": "请输入8位以上的密码",
  "login.form.password.placeholder": "请输入密码",
  "login.form.forgot": "忘记密码",
  "login.server.tips": "继续使用即代表你同意 MASOBU麻涩部 的",
  "login.server.tips.sub": "服务条款",
  "login.privacy.tips": "并确认你已详阅我们的",
  "login.privacy.tips.sub": "隐私权政策",
  "login.btn": "登入",
  "login.page.text": "登入页",
  "login.btn.register": "注册",
  "login.btn.continue": "继续",
  "login.btn.confirm": "确认",
  "login.phone.register.tips": "尚未注册的手机号验证后自动注册",
  "login.form.email": "邮箱",
  "login.form.email.placeholder": "请输入邮箱地址",
  "login.form.email.validate": "请输入正确的邮箱",
  "login.form.confirm-password": "确认密码",
  "login.form.confirm-password.validate": "两次密码不一致",
  "login.email.register.tips": "MASOBU 新用戶？",
  "login.email.register.tips.btn": "建立免费帐号",
  "login.email.login.tips": "已有账号了",
  "login.email.login.btn": "登入",
  "login.forgot.save.btn": "保存",
  "login.bind.save.btn": "确认",
  "login.modify.mode-select": "验证方式",
  "login.modify.save.btn": "保存",
  // 新增
  "login.modify.save.success": "修改成功",
  // web-sdk 登陆
  "login.welcome.text": "欢迎来到",
  "login.highlight.name": "麻涩部",
  "login.button.existing.account": "登录现有账号",
  "login.button.play.directly": "直接游玩",
  "login.button.bind.account": "绑定账号",
  "login.button.bind.or": "或",
  "login.button.bind.continue": "继续",
  "login.button.bind.password": "密码绑定",
  "login.button.bind.codekey": "验证码绑定",
  "bind.status.success": "绑定成功",
  "bind.status.fail": "绑定失败",
  "bind.button.backHome": "返回首页",
  "bind.button.enter.game": "进入游戏",
  "bind.button.retry": "再次尝试",
  "bind.account.bind.title": "绑定账号",
  "bind.account.bind.tip": "需要绑定账号才可进行消费动作",
  "bind.account.bind.button.bind": "绑定账号",
  "bind.account.bind.button.login": "登入现有账号",
  "login.form.phone.again.placeholder": "请再次输入密码",
  // 站内信
  "notice.title": "通知中心",
  "notice.tab.account": "账户通知",
  "notice.tab.benefits": "活动福利",
  "notice.tab.system": "公告",
  "notice.no-message": "暂无消息",
  "notice.more": "查看更多",
  "notice.date": "日期",
  // 年龄
  "age.limit.title": "您是否已满 18 岁？",
  "age.limit.sub-top": "网站包含成人内容，请确认您已成年。",
  "age.limit.sub-bottom":
    "本网站包含色情内容和裸露。点击『是的 - 我已满 18 岁』即表示您已阅读并同意我们的政策和指引，并确认您已达到合法观看年龄。",
  "age.limit.confirm": "已满18岁",
  "age.limit.cancel": "未满18岁",
  "age.limit.toast": "很抱歉，未满18岁用户不可进入网站",
  // 邀请
  "inviter.link": "邀请链接",
  "inviter.code": "邀请码",
  "inviter.btn": "复制",
  "inviter.tips.top": "您可以选择分享给好友，好友直接注册",
  "inviter.tips.bottom": "或者分享邀请码给好友，好友注册时填写邀请码",
  // 新增
  "search.close": "关闭",
  "search.no-result": "暂无相关结果",
  "search.title": "搜索结果",
  "search.placeholder": "请输入",
  // 任务限制
  "task.limit.count.get": "获得奖励次数",
  "task.limit.count": "剩余奖励次数",
  "game.purchase.not.exist": "该游戏不存在",
  // 道具购买
  "props.title": "购买",
  "props.name.label": "道具名称",
  "props.count.label": "道具数量",
  "props.price.label": "金额",
  "props.m.coin": "M币",
  "props.m.coupons": "M券",
  "props.m.coins.balance": "M币余额",
  "props.m.coupons.balance": "M券余额",
  "props.m.coins.pay": "M币支付",
  "props.m.coupons.discount": "M券抵扣",
  "props.m.coupons.discount.limit": "上限",
  "props.m.no.enough": "余额不足，还需充值",
  "props.buy": "购买",
  "props.buy.success": "购买成功",
  "props.charge": "前往充值",
  "props.tips": "虚拟商品一经出售不与退款",
  "props.no.exist": "该道具不存在",
  "props.limit.left": "低于",
  "props.limit.right": "M币支付免确认",
  "activity.time": "活动时间",
  "activity.btn.open": "查看活动详情",
  "activity.btn.close": "收起",
  "activity.btn.top": "回顶部",
  "account.virtual.m.coupons": "优惠券",
  "coming.soon": "即将上线",
  "account.virtual.m.coin": "币",
  "account.virtual.m.points": "券",
  account: "账号",
  "layout.header.login": "立即游玩",
  "footer.join": "加入MASOBU社群頻道",
  "footer.info": "信息",
  "coupon.page.title": "我的优惠券",
  "coupon.page.record": "帐户记录",
  "coupon.page.memberTitle": "可使用项目",
  "coupon.page.btn": "兑换优惠券",
  "coupon.page.coupon.placeholder": "请输入优惠码",
  "coupon.page.tab.valid": "可用优惠券",
  "coupon.page.tab.invalid": "已失效优惠券",
  "coupon.page.game.title": "可使用游戏",
  "coupon.page.game.tips": "已使用或已失效优惠券将在30天后消失",
  "coupon.exchange.confirm": "确定",
  "coupon.exchange.cancel": "取消",
  "coupon.exchange.know": "知道了",
  "coupon.card.type": "折扣券",
  "coupon.card.limit.date": "有效期限至",
  "coupon.card.use": "使用",
  "coupon.card.used": "已使用",
  "coupon.card.range.all": "所有游戏可使用",
  "coupon.card.range.part": "部分游戏可使用",
  "coupon.card.range.online": "在线游戏可使用",
  "coupon.card.range.offline": "线下游戏可使用",
  "coupon.card.range.h5": "H5游戏可使用",
  "coupon.card.expired": "已失效",
  "coupon.use.m.discount": "M券抵扣",
  "coupon.use.coupon": "优惠券",
  "coupon.use.no": "暂无优惠",
  "coupon.select.title": "可使用优惠券",
  "coupon.select.btn.confirm": "确定",
  "coupon.select.btn.cancel": "取消",
  "coupon.select.discount": "抵扣",
  "coupon.card.noData": "目前没有可使用的优惠券",
  "vip.card": "会员卡",
  "vip.card.earnings": "会员卡权益",
  "vip.card.earningsContent": "解锁月卡享专属权益",
  "vip.card.couponText": "有优惠券可使用",
  "vip.card.timeLeft": "剩余时间: ",
  "vip.card.surplus": "剩余: ",
  "vip.card.activeSurplus": "生效中: 剩余",
  "vip.card.alreadyOwned": "已拥有",
  "vip.card.day": "天",
  "vip.card.popupLv": "涩点等级",
  "vip.card.popupTip": "新春限定 - 权益升级至60天",
  "vip.card.popupLv01": "正式部员",
  "vip.card.popupLv02": "初级部员",
  "vip.card.popupLv03": "中级部员",
  "vip.card.popupLv04": "高级部员",
  "vip.card.popupLabel01": "涩点点系列全免费",
  "vip.card.popupQuarterLabel01": "真人游戏1折优惠券",
  "vip.card.popupQuarterLabel02": "赠送 {count} M能量",
  "vip.card.popupQuarterLabel03": "麻豆国际版 VIP {count}金币",
  "vip.card.popupQuarterLabel04": "点点系列女优 无限玩",
  "vip.card.popupQuarterLabel05": "专属VPN 会员{count}个月",
  "vip.card.popupQuarterLabel06": "充值即可 加赠M币",
  "vip.card.popupQuarterLabel07": "点点未公开 图鉴季度赠",
  "vip.card.popupQuarterLabel08": "麻涩部游戏 试玩先锋",
  "vip.card.popupQuarterLabel09": "双周赠 单机5折优惠券",
  "vip.card.popupQuarterLabel10": "季度赠送 麻豆传媒实体物",
  "vip.card.tag01": "涩点点免费畅玩",
  "vip.card.tag02": "优惠券先锋",
  "vip.card.tag03": "M币加赠",
  "vip.card.tag04": "领取实体物",
  "vip.card.tag05": "试玩前锋",
  "vip.card.vipLv1": "青铜级",
  "vip.card.vipLv2": "白银级",
  "vip.card.vipLv3": "黄金级",
  "vip.card.vipLv4": "钻石级",
  "vip.card.membershipLvExplain": "会员等级说明",
  "vip.card.currentLv": "目前等级",
  "vip.card.privilegedContent": "特權內容",
  "vip.card.rebates": "返赠前 { count } M币",
  "vip.card.energy": "赠送 {count} M能量",
  "vip.card.payBtn01": "原价 { listPrice } ",
  "vip.card.payBtn02": "特价 { salePrice } / { validDays } 天",
  "vip.month": "月",
  "vip.card.masobuEarnings": "麻涩部點卡 {day}天權益",
  "vip.card.vipExchangeBtn": "權益兌換步驟",
  "vip.card.benefitsRedemptionGuidelines": "权益兑换须知",
  "vip.card.vipExchangePopupTipTitle01": "实体物寄送登记流程",
  "vip.card.vipExchangePopupTipSecondTitle01": "1. 填写寄送信息",
  "vip.card.vipExchangePopupTipText01":
    "请复制以下模板，填写完整后务必确认信息正确，以确保顺利寄送。",
  "vip.card.vipExchangePopupTipSecondTitle02": "寄送信息模板：",
  "vip.card.vipExchangePopupTipText02":
    "<item> • 麻涩部 UID（请提供官网上显示的完整 UID）</item><item>• 收件人姓名（需与身份证姓名一致）</item><item>• 手机号码（请填写正确手机号码，以便快递联系）</item><item>• 收件地址（请提供详细的省/市/区 + 具体街道地址）</item><item>• 邮递区号（请填写正确的区域邮递区号）</item>",
  "vip.card.vipExchangePopupTipSecondTitle03": "2. 回传完整信息",
  "vip.card.vipExchangePopupTipText03":
    "请联系麻涩部官方客服，并回传完整且正确的寄送信息，以便安排配送。",
  "vip.card.vipExchangePopupTipSecondTitle04": "3. 信息安全保障",
  "vip.card.vipExchangePopupTipText04":
    "麻涩部承诺保护您的个人隐私，所有信息仅用于寄送，不会用于其他用途。",
  "vip.card.vipExchangePopupTipTitle02": "专属会员及金币领取流程",
  "vip.card.vipExchangePopupTipSecondTitle05": "1. 进入平台并注册",
  "vip.card.vipExchangePopupTipText05":
    "点选 M代表加速器 / 麻豆传媒国际版，完成会员注册。",
  "vip.card.vipExchangePopupTipSecondTitle06": "2. 联系客服验证",
  "vip.card.vipExchangePopupTipText06":
    " 注册后，请联系 麻涩部官方客服，提供您的注册邮箱。",
  "vip.card.vipExchangePopupTipSecondTitle07": "3. 权益发放",
  "vip.card.vipExchangePopupTipText07":
    "我们将在 5 个工作天内 开通您的会员权益或发放专属金币。",
  "vip.card.vipExchangePopupTipSecondTitle08": "4. 通知确认",
  "vip.card.vipExchangePopupTipText08": " 兑换完成后，您将收到站内信通知。",
  "vip.card.vipExchangePopupTipSecondTitle09": "5. 问题咨询",
  "vip.card.vipExchangePopupTipText09":
    "如有任何问题，请随时联系 麻涩部官方客服。",
  "vip.card.vipExchangePopupBtn01": "M代表加速器",
  "vip.card.vipExchangePopupBtn02": "麻豆传媒国际版",
  "vip.card.vipExchangePopupBtn03": "联系客服",
  "vip.card.masobuSecondEarnings":
    "购买麻涩部点卡之后可以解锁M能量，可提升涩点等级，并且可以免费游玩涩/恋点点全系列",
  "vip.vipPrefecture.lvTip": "等级说明",
  "vip.vipPrefecture.renewalTip": "续订Masobu点卡",
  "vip.vipPrefecture.renewalPopupTip":
    "「別急，您的月卡还在有效期内哦！剩余**[{count}]天**，等到期后再来续卡吧～我们会一直等着您！」",
  "vip.vipPrefecture.tab01": "游戏库",
  "vip.vipPrefecture.tab02": "愿望清单",
  "vip.vipPrefecture.tab03": "任务列表",
  "vip.vipPrefecture.btn01": "帐号凭证",
  "vip.vipPrefecture.btn02": "切换帐号",
  "vip.vipPrefecture.btn03": "找回帐号",
  "vip.vipPrefecture.boundUp": "已绑定",
  "vip.vipPrefecture.tipText": "记得保存凭证，随时找回账号哦～",
  "signIn.signAgain": "补签",
  "signIn.energyValue": "能量值",
  "signIn.signInComplete": "签到完成",
  "signIn.title": "任务面板",
  "signIn.signInRuleText":
    "签到任务每7天循环一次，若有漏签便不可往后签到，您可花费5 M币进行补签。",
  "signIn.signInRule": "补签到说明规则",
  "signIn.claimReward": "领取奖励",
  "signIn.completed": "已完成",

  "recharge.card.unlock.title": "你已解锁隐藏优惠！",
  "recharge.card.unlock.subtitle": "开卡立享涩点点全系列免费玩",
  "recharge.card.unlock.price.original": "原价{amount} ",
  "recharge.card.unlock.price.new": "只要 {amount} ",
  "recharge.product.pay.unit": "CNY",
  "recharge.card.unlock.chance": "还剩 {count} 次机会",
  "transaction.title": "交易概览",
  "transaction.item.label": "项目",
  "transaction.price.label": "价格",
  "transaction.price.value": "{amount}$",
  "transaction.payment.method": "选择支付方式",
  "transaction.button.confirm": "确认支付",
  "transaction.button.cancel": "取消",
  "transaction.unit": "月卡",

  "recharge.card.title": "隐藏优惠最后机会！",
  "recharge.card.unlock.message": "你已解锁隐藏优惠！",
  "recharge.card.price.original": "原价{count}{currency}",
  "recharge.card.price.current": "只要{count}{currency}",
  "recharge.card.price.combined":
    "原价{originalPrice}{currency}  只要{currentPrice}{currency}",
  "recharge.card.button.text": "点击前往购买",
  "recharge.card.benefit.prefix": "开卡立享",
  "recharge.card.benefit.highlight": "涩点点、恋点点游戏全系列",
  "recharge.card.benefit.suffix": "免费玩",
  "recharge.card.benefit.tips": "*虚拟商品一经售出不与退款",
  "recharge.card.payment.error": "支付异常，请重试",
  "recharge.card.payment.selectMethod": "请选择支付方式",
  "game.detail.img": "游戏画面",
  "game.Platformsil.ios": "仅支持ios平台",
  "game.Platformsil.android": "仅支持android平台",
  noVideo: "暂无宣传视频",
  noDownload: "暂无此平台下载链接",
  "recharge.bind.secondTitle": "需要绑定帐号才可进行消费动作",
  "member.accountCredentials.tips":
    "如帐号丢失，请到「会员专区」找回，上传或扫描凭证",
  "member.accountCredentials.btn": "保存帐号凭证至装置",
  "member.recoverAccount": "找回帐号",
  "member.recoverAccount.btn01": "上传凭证",
  "member.recoverAccount.btn02": "扫描凭证",
  "qrcode.fetch.failed": "获取二维码失败",
  "image.generation.failed": "生成图片失败",
  // 扫码登录相关
  "qrcode.scan.title": "扫描登录",
  "qrcode.scan.guide": "对准二维码即可自动识别",
  "qrcode.scan.album": "相册",
  "qrcode.scan.cancel": "取消",
  "qrcode.error.device": "对不起：未识别到扫描设备!",
  "qrcode.error.camera": "启动相机播放失败，请重试",
  "qrcode.error.video": "视频播放错误，请重试",
  "qrcode.error.https":
    "获取浏览器录音功能，因安全性问题，需要在localhost 或 127.0.0.1 或 https 下才能获取权限！",
  "qrcode.error.invalid": "无效的二维码，请重试",
  "qrcode.error.invalid2": "无效的二维码",
  "qrcode.error.processing": "图片处理失败，请重试",
  "qrcode.error.reading": "文件读取失败，请重试",
  "qrcode.error.init": "初始化摄像头失败，请刷新重试",
  "qrcode.error.login": "登录失败，请重试",
  "component.noticeIcon.empty": "暂无数据",

  // 嵌入式
  "Recharge.title": "充值账号",
  "Recharge.error.general": "操作失败，请稍后重试",
  "Recharge.balance.coin": "M币余额",
  "Recharge.balance.coupon": "M券余额",
  "Recharge.payment.methods": "选择支付方式",
  "Recharge.payment.status.to_pay": "等待支付",
  "Recharge.payment.status.in_pay": "支付处理中",
  "Recharge.payment.status.pay_success": "支付成功",
  "Recharge.payment.status.pay_fail": "支付失败",
  "Recharge.payment.status.pay_cancel": "支付已取消",
  "Recharge.payment.status.refund": "已退款",
  "Recharge.payment.success": "支付成功！",
  "Recharge.payment.fail": "支付失败！",
  "Recharge.product.title": "商品",
  "Recharge.product.price": "价格",
  "Recharge.product.first": "首充专享",
  "Recharge.product.coin": "M币",
  "Recharge.product.reward": "赠送 {amount} M券",
  "Recharge.button.next": "下一步",
  "Recharge.button.cancel": "取消",
  "Recharge.button.confirm": "确认支付",
  "Recharge.button.close": "关闭",
  "Recharge.tips": "*虚拟商品一经出售不与退款",
  "Recharge.noPayMethod": "暂无可用支付方式",

  "GameItemPurchase.propsTitle": "购买道具",
  "GameItemPurchase.propsNameLabel": "道具名称",
  "GameItemPurchase.propsCountLabel": "数量",
  "GameItemPurchase.propsPriceLabel": "价格",
  "GameItemPurchase.propsMCoinsBalance": "M币余额：",
  "GameItemPurchase.propsMCouponsBalance": "M券余额：",
  "GameItemPurchase.propsMCoinsPay": "M币支付",
  "GameItemPurchase.propsMCouponsDiscount": "M券抵扣",
  "GameItemPurchase.propsMCouponsDiscountLimit": "最多可抵扣",
  "GameItemPurchase.propsMNoEnough": "余额不足，还需充值",
  "GameItemPurchase.propsMCoin": "M币",
  "GameItemPurchase.propsMCoupons": "M券",
  "GameItemPurchase.propsLimitLeft": "低于",
  "GameItemPurchase.propsLimitRight": "M币支付免确认",
  "GameItemPurchase.propsBuy": "购买",
  "GameItemPurchase.propsCharge": "去充值",
  "GameItemPurchase.propsTips": "*虚拟商品一经出售不与退款",
  "GameItemPurchase.propsBuySuccess": "购买成功",

  rechargeCardTitle: "隐藏优惠最后机会！",
  rechargeCardUnlockTitle: "你已解锁隐藏优惠！",
  rechargeCardPriceOriginal: "原价 {amount} ",
  rechargeCardPriceNew: "特惠价 {amount} ",
  rechargeCardBenefitPrefix: "开卡即可享受",
  rechargeCardBenefitHighlight: "涩点点、恋点点游戏全系列",
  rechargeCardBenefitSuffix: "永久免费畅玩特权",
  rechargeCardButtonText: "立即购买",
  rechargeCardImageAlt: "VIP卡优惠",
  rechargeCardNoChannels: "暂无可用支付渠道",
  rechargePaySuccessAlt: "支付成功",
  rechargePaySuccessText: "完成！",
  rechargePayFailAlt: "支付失败",
  rechargePayFailText: "失败！",

  paymentTitle: "支付确认",
  paymentProductLabel: "商品",
  paymentPriceLabel: "价格",
  paymentUnit: "VIP会员卡",
  paymentMethodTitle: "支付方式",
  paymentMethodAlt: "{method}支付",
  paymentConfirmButton: "确认支付",
  paymentCancelButton: "取消",
  paymentFooterNote: "支付即表示同意服务条款",
  paymentSelectMethodFirst: "请选择支付方式",
  paymentFailMessage: "支付失败，请重试",
  paymentSystemError: "系统错误，请稍后重试",
  paymentProcessing: "支付处理中...",
  paymentSuccess: "支付成功！",
  paymentAmount: "支付金额",
  paymentOrderNumber: "订单号",
  paymentTime: "支付时间",
};
