import avgHandler from "@/utils/avgHandler";
/**
 * 判断页面是在unitysdk运行的且不需要请求全局api
 */

export const isAvgWebLoginPage = () => {
  if (
    avgHandler.isSDK() &&
    ["/device-login", "/login"].includes(window.location.pathname)
  ) {
    return true;
  }
  return false;
};

/**
 * 解析URL搜索参数为对象
 * @param searchStr - URL中的搜索参数字符串(可以包含或不包含开头的'?')
 * @returns 包含所有参数键值对的对象
 */
export function parseSearchParams(searchStr: string): Record<string, string> {
  // 移除开头的问号(如果存在)
  const search = searchStr.startsWith("?") ? searchStr.substring(1) : searchStr;

  // 如果搜索字符串为空，返回空对象
  if (!search) {
    return {};
  }

  // 将搜索字符串分割为键值对数组
  const pairs = search.split("&");
  const result: Record<string, string> = {};

  // 遍历每个键值对并添加到结果对象中
  pairs.forEach((pair) => {
    // 处理没有值的参数(例如 "key")
    if (pair.indexOf("=") === -1) {
      result[decodeURIComponent(pair)] = "";
      return;
    }

    // 分割键和值
    const [key, value] = pair.split("=");

    // 解码URI组件并添加到结果对象
    result[decodeURIComponent(key)] = decodeURIComponent(value || "");
  });

  return result;
}
