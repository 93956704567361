// @ts-ignore
/* eslint-disable */
import { request } from "umi";

/** 绑定邮箱 绑定邮箱 POST /c/user/bindEmail */
export async function bindEmail(
  body: API.EmailBindReq,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/user/bindEmail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 绑定手机号 绑定手机号 POST /c/user/bindPhone */
export async function bindPhone(
  body: API.PhoneBindReq,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/user/bindPhone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 GET /c/user/confirmAge */
export async function confirmAge(options?: { [key: string]: any }) {
  return request<API.RVoid>("/c/user/confirmAge", {
    method: "GET",
    ...(options || {}),
  });
}

/** 获取用户信息 获取用户信息 GET /c/user/getUserInfo */
export async function getInfo(options?: { [key: string]: any }) {
  return request<API.RSysUserBaseResp>("/c/user/getUserInfo", {
    method: "GET",
    ...(options || {}),
  });
}

/** 修改密码 修改密码 POST /c/user/modifyUserPassword */
export async function modifyUserPassword(
  body: API.ModifyUserPasswordReq,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/user/modifyUserPassword", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 更新用户信息 更新用户信息 POST /c/user/updateInfo */
export async function updateInfo(
  body: API.UpdateInfoReq,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/user/updateInfo", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取用户账户信息
export async function getUserAccount() {
  return request<API.GetUserAccountResp>("/c/user/account/getUserAccount", {
    method: "GET",
  });
}

// 获取用户充值记录
export async function getUserRechargeRecords() {
  return request<API.GetUserRechargeRecordsResp>(
    "/c/user/account/getUserRechargeRecords",
    {
      method: "GET",
    }
  );
}

// 获取用户购买记录
export async function getUserPurchaseRecords() {
  return request<API.GetUserPurchaseRecordsResp>(
    "/c/user/account/getUserPurchaseRecords",
    {
      method: "GET",
    }
  );
}

// 获取B币的交易流水
export async function getUserBCoinTransactionRecords() {
  return request<API.GetUserBCoinTransactionRecordsResp>(
    "/c/user/account/getUserBCoinTransactionRecords",
    {
      method: "GET",
    }
  );
}

// 获取用户站内信
export async function getUserMessage() {
  return request<API.GetUserMessageResp>("/c/user/message/getUserMessage", {
    method: "GET",
  });
}

// 读取站内信
export async function userReadMessage(messageType: string) {
  return request("/c/user/message/read", {
    method: "POST",
    data: {
      messageType,
    },
  });
}

// 忘记手机密码
export async function forgotPhonePassword(data: {
  phoneNumber: string;
  smsCaptcha: string;
  newPassword: string;
}) {
  return request("/c/user/forgotPhonePassword", {
    method: "POST",
    data,
  });
}

// 忘记邮箱密码
export async function forgotEmailPassword(data: {
  email: string;
  emailCode: string;
  newPassword: string;
}) {
  return request("/c/user/forgotEmailPassword", {
    method: "POST",
    data,
  });
}

// 获取邀请码
export async function getInviterCode() {
  return request("/c/user/getInviterCode", {
    method: "GET",
  });
}

// 获取公告列表
export async function getNoticeList() {
  return request("/c/notice/list", {
    method: "GET",
  });
}

// 获取帐户记录M能量
export async function getAccountRecordEnergy(params: { by: string | number }) {
  return request("/c/user/account/transactions/energy", {
    method: "GET",
    params: params,
  });
}

// 获取帐户记录M币
export async function getAccountRecordM(params: { by: string | number }) {
  return request("/c/user/account/transactions/m", {
    method: "GET",
    params: params,
  });
}

// 获取帐户记录支付
export async function getAccountRecordRecharge(params: {
  by: string | number;
}) {
  return request("/c/user/account/transactions/recharge", {
    method: "GET",
    params: params,
  });
}

// 游客登录
export async function guestLogin(deviceId: string, dc: string) {
  return request("/c/login/guestLogin", {
    method: "POST",
    data: {
      guestDeviceId: deviceId,
      dc: dc,
    },
  });
}
export async function getInfoQrde() {
  return request("/c/user/qrCode/get", {
    method: "GET",
  });
}
// 二维码登录
export async function qrcodeLogin(data: { value: string }) {
  return request("/c/user/qrCode/getUserInfo", {
    method: "POST",
    data: data,
  });
}

// 获取用户设备列表
export async function getDevicesList() {
  return request("/c/user/account/devices", {
    method: "GET",
  });
}

// 用户设备登出
export async function accountDeviceSignUp(data: { id: number }) {
  return request("/c/user/account/device/quit", {
    method: "POST",
    data: data,
  });
}

// 查询token状态
export async function getTokenStatus(token: string) {
  return request<API.TokenStatusResp>("/c/login/getTokenStatus", {
    method: "POST",
    data: {
      token,
    },
  });
}
