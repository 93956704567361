// @ts-ignore
/* eslint-disable */
import { request } from "umi";

/** 用户邮箱注册 用户邮箱注册 POST /c/register/registerEmail */
export async function registerEmail(
  body: API.EmailRegisterReq,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/register/registerEmail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 用户手机注册 用户手机注册 POST /c/register/registerPhone */
export async function register(
  body: API.PhoneRegisterReq,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/register/registerPhone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 游客绑定邮箱 绑定邮箱 POST /c/user/guest/bindEmail */
export async function guestBindEmail(
  body: API.EmailRegisterReq,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/user/guest/bindEmail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}
