// @ts-ignore
/* eslint-disable */
// API 更新时间：
// API 唯一标识：
import * as captcha from "./captcha";
import * as contentConfig from "./contentConfig";
import * as coupon from "./coupon";
import * as file from "./file";
import * as game from "./game";
import * as gameInteraction from "./gameInteraction";
import * as login from "./login";
import * as marketingTask from "./marketingTask";
import * as product from "./product";
import * as rechargeCard from "./rechargeCard";
import * as register from "./register";
import * as signIn from "./signIn";
import * as user from "./user";
import * as vipPrefecture from "./vipPrefecture";

export default {
  contentConfig,
  file,
  captcha,
  login,
  user,
  game,
  gameInteraction,
  register,
  product,
  marketingTask,
  coupon,
  signIn,
  vipPrefecture,
  rechargeCard,
};
