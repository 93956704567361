// @ts-ignore
/* eslint-disable */
import { request } from "umi";

// 获取充值产品列表
export async function getRechargeProducts(params?: { lan: string }) {
  return request<API.GetRechargeProductsResp>(
    "/c/product/getRechargeProducts",
    {
      method: "GET",
      params: {
        ...params,
      },
    }
  );
}

// 获取支付通道列表
export async function listRechargeChannel(data: {
  rchgFor: string;
  money: string | number;
}) {
  return request<API.ListRechargeChannelResp>(
    "/c/product/listRechargeChannel",
    {
      method: "POST",
      data,
    }
  );
}

// 购买充值产品(下单支付)
export async function recharge(data: API.RechargePostReq) {
  return request<API.BuyRechargeResp>("/c/product/recharge", {
    method: "POST",
    data,
  });
}

// 充值调试
export async function rechargeMock(url: string, data: any) {
  return request(url, {
    method: "POST",
    data,
  });
}

// 获取VIP商品
export async function getVipProducts(params: { lan: string }) {
  return request("/c/vipcard/list", {
    method: "GET",
    params: {
      ...params,
    },
  });
}

// 购买月卡
export async function buyVipCard(data: any) {
  return request("/c/vipcard/buy", {
    method: "POST",
    data,
  });
}

// 获取可使用优惠券月卡列表
export async function getCouponVipProducts(params: any) {
  return request("/c/coupon/queryUseVipCardListByCouponId", {
    method: "GET",
    params: {
      ...params,
    },
  });
}
