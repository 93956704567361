// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

// 获取充值产品列表
export async function getBcionTaskList () {
  return request<API.GetBCoinTaskListResp>('/c/marketingtask/getBcionTaskList', {
    method: 'GET'
  })
}
