// @ts-ignore
/* eslint-disable */
import { request } from "umi";

// 执行签到及补签
export async function doSignIn(data: any) {
  return request("/c/checkin/do", {
    method: "POST",
    data,
  });
}

// 获取签到列表
export async function getSignInList(params: any) {
  return request("/c/checkin/status", {
    method: "GET",
    params,
  });
}
