import { Loading } from "@nutui/nutui-react";

export default () => {
  // TODO: 加载页面，图标和文字需要配合设计稿调整。
  return (
    <>
      <div className="h-100vh w-100vw grid place-content-center text-center">
        {/* <Loading type="spinner" /> */}
        <Loading></Loading>
      </div>
    </>
  );
};
