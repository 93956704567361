export default {
  // 菜單
  "tab.menu": "菜單",
  "tab.myAccount": "我的賬號",
  "tab.signIn": "登錄",
  "tab.hall": "遊戲大廳",
  "tab.task": "任務",
  "tab.pay": "充值中心",
  "tab.slide": "滑動",
  "menu.home": "首頁",
  "menu.redeem-codes": "兌換代碼",
  "menu.online-game": "在線遊戲",
  "menu.offline-game": "遊戲一覽",
  "menu.h5-game": "H5遊戲",
  "menu.virtual-coin": "充值中心",
  "menu.game-detail": "遊戲詳情",
  "menu.game-auditing": "遊戲審核",
  "menu.account-center": "個人中心",
  "menu.age-limit": "年齡限製",
  "menu.privacy": "隱私權政策",
  "menu.server": "服務條款",
  "menu.search": "搜索",
  "menu.activity": "活動中心",
  "menu.vipPrefecture": "會員專區",
  "menu.signUp": "登出",
  "menu.currentDevice": "当前登录设备",
  "menu.lang": "語言",
  "menu.toUp": "我要儲值",
  "menu.payVip": "續訂點卡",
  "menu.energy": "能量",
  "menu.mEnergy": "M能量",
  "menu.payVipCard": "購買會員卡",
  "menu.payVipCardTip": "解鎖，享更多返利優惠!",
  "menu.bindAccount": "綁定帳號",
  "menu.loginAccount": "登入現有帳號",
  "lang.cancel": "取消",
  // 導航欄
  "header.search": "搜索",
  "header.login": "會員登入",
  "hedaer.register": "立即註冊",
  "header.create-center": "創作中心",
  "header.signIn": "簽到",
  // 首頁
  "home.view-more": "觀看更多",
  // 遊戲列錶（在線遊戲/下載遊戲通用）
  "game-list.category": "類別",
  "game-list.category.more": "更多類別",
  "game-list.tag": "標籤",
  "game-list.tag.more": "更多標籤",
  "game-list.filter": "過濾器",
  "game-list.filter.clear": "清除全部",
  "game-list.filter.complete": "篩選",
  // 虛擬幣
  "virtual-coin.recharge-record": "充值記錄",
  "virtual-coin.buy-record": "購買記錄",
  "virtual-coin.check-record": "檢視明細",
  "virtual-coin.m-coin-tips":
    "您可以使用M幣購買本平台的遊戲，可通過充值獲得M幣。匯率結算單位：美元（實時匯率）",
  "virtual-coin.m-coupon-tips":
    "M券可用於抵扣M幣，比例為1:1，可通過參與活動獲得",
  "virtual-coin.finish": "立即完成",
  "virtual-coin.m-coin": "M幣",
  "virtual-coin.onlinePayment": "線上支付",
  "virtual-coin.vipCard": "權益卡",
  "virtual-coin.m-coupon": "M券",
  "virtual-coin.copy.success": "複製成功",
  "virtual-coin.pay": "支付",
  "virtual-coin.discount": "優惠",
  "virtual-coin.deduction": "抵扣",
  "virtual-coin.m-coupon.detail": "M券明細",
  "virtual-coin.pay-pop.pay-mode": "選擇支付方式",
  "virtual-coin.pay-pop.product": "項目",
  "virtual-coin.pay-pop.m-coin": "M幣",
  "virtual-coin.pay-pop.price": "價格",
  "virtual-coin.pay-pop.confirm": "確認支付",
  "virtual-coin.pay-pop.next": "下一步",
  "virtual-coin.pay-pop.cancel": "取消",
  "virtual-coin.pay-pop.card-no": "信用卡卡號",
  "virtual-coin.pay-pop.card-no.placeholder": "請輸入信用卡卡號",
  "virtual-coin.pay-pop.exp-date": "有效期限",
  "virtual-coin.pay-pop.exp-date.placeholder": "按格式輸入：05/2024",
  "virtual-coin.pay-pop.exp-date.validate-tips": "請輸入正確的格式",
  "virtual-coin.pay-pop.cvv": "安全碼",
  "virtual-coin.pay-pop.cvv.placeholder": "輸入卡背後三位數",
  "virtual-coin.pay-pop.first-name": "名字",
  "virtual-coin.pay-pop.first-name.placeholder": "輸入名",
  "virtual-coin.pay-pop.last-name": "姓氏",
  "virtual-coin.pay-pop.last-name.placeholder": "輸入姓",
  "virtual-coin.pay-pop.address": "賬單地址",
  "virtual-coin.pay-pop.address.placeholder": "輸入地址",
  "virtual-coin.pay-pop.privince": "州/省",
  "virtual-coin.pay-pop.privince.placeholder": "輸入州省",
  "virtual-coin.pay-pop.zip-code": "郵遞區號",
  "virtual-coin.pay-pop.zip-code.placeholder": "輸入郵遞區號",
  "virtual-coin.pay-pop.country": "國家",
  "virtual-coin.pay-pop.country.placeholder": "輸入國家",
  "virtual-coin.pay-pop.phone": "手機號碼",
  "virtual-coin.pay-pop.phone.placeholder": "輸入手機號碼",
  "virtual-coin.pay-pop.pay.fail": "支付失敗",
  "virtual-coin.award": "加贈",
  "virtual-coin.first": "首充專享",
  "virtual-coin.date-purchased": "購買日期",
  // 新增
  "virtual-coin.pay-pop.mode-list.tips": "暫無支付通路，請稍後再試",
  // 文件上載組件
  "file-upload.btn": "文件上載",
  // 個人中心
  "account-center.tabs.account": "帳戶信息",
  "account-center.tabs.collect": "我的收藏",
  "account-center.tabs.like": "我的點讚",
  "account-center.tabs.history": "歷史紀錄",
  "account-center.tabs.buy": "已購遊戲",
  "account-center.tabs.sub.online": "在線遊戲",
  "account-center.tabs.sub.offline": "下載遊戲",
  "account-center.nickname.label": "昵稱",
  "account-center.nickname.placeholder": "請輸入昵稱",
  "account-center.avatar.label": "個人頭像",
  "account-center.id": "用戶ID",
  "account-center.save.btn": "保存修改",
  "account-center.email.label": "電子信箱",
  "account-center.phone.label": "手機號碼",
  "account-center.bind.btn": "點選綁定",
  "account-center.password.label": "密碼設定",
  "account-center.modify-password.btn": "重設密碼",
  "account-center.save.success": "保存成功",
  // 遊戲卡片
  "game-card.free": "免費",
  "game-card.discount": "限時優惠",
  "game-card.m-coin": "M幣",
  "game-card.publicity.wait": "敬請期待",
  "game-card.publicity.try": "火熱試玩中",
  "game.price.coupon": "使用優惠後",
  "game.price.origin": "原價",
  "game-card.mobileGameGallery": "手遊館",
  // 遊戲詳情
  "game.toDetail": "前往詳情",
  "game.intro": "遊戲介紹",
  "game.announcement": "更新公告",
  "game.announcement.more": "檢視更多",
  "game.comment": "全部評論",
  "game.comment.send-success": "評論成功",
  "game.comment.placeholder": "請輸入你的評論",
  "game.comment.check-reply": "檢視回覆",
  "game.comment.hidden-reply": "隱藏回覆",
  "game.comment.creator": "作者",
  "game.comment.top": "置頂評論",
  "game.comment.reply": "回覆",
  "game.comment.more": "檢視更多",
  "game.play.free": "立即免費玩",
  "game.play": "立即玩",
  "game.play.pc": "前往PC游玩",
  "game.download": "立即下載",
  "game.download.pop.title": "下載遊戲",
  "game.download.pop.tips": "請選擇您的裝置平臺",
  "game.purchased": "你已購買此遊戲",
  "game.online-tips": "享受全熒幕遊玩",
  "game.offline-tips": "點選下載安裝包",
  "game.like": "點贊",
  "game.collect": "收藏",
  "game.share": "分享",
  "game.share.copy": "點選複製",
  "game.copy.success": "複製成功",
  "game.info.title": "遊戲信息",
  "game.info.creatorInfo": "創作者信息",
  "game.info.creator": "創作者",
  "game.info.release-time": "上線時間",
  "game.info.lang": "支援語言",
  "game.info.group": "官方社群",
  "game.guess": "猜你喜歡",
  "game.activity.countdown": "倒計時",
  "game.buy": "立即購買",
  "game.buy.m": "M幣購買",
  "game.buy.direct": "直接購買",
  "game.buy.direct.btn": "確定購買",
  "game.buy.direct.wait": "您有在付款中的購買訂單",
  "game.buy.direct.cancel": "取消",
  "game.buy.direct.buy.success": "購買成功",
  "game.buy.direct.buy.success.done": "完成",
  "game.buy.direct.buy.fail": "購買失敗",
  "game.buy.direct.buy.fail.reason": "失敗原因",
  "game.buy.direct.buy.fail.done": "知道了",
  "game.buy.direct.buy.loading": "購買結果查詢中",
  "game.buy.direct.buy.loading.done": "已完成支付",
  "game.buy.model": "請選擇購買方式",
  "game.buy.pop.title": "購買遊戲",
  "game.buy.pop.name": "遊戲名稱",
  "game.buy.pop.price": "金額",
  "game.buy.pop.m-coin": "M幣",
  "game.buy.pop.m-coupon": "M券",
  "game.buy.pop.discount": "優惠",
  "game.buy.pop.pay": "支付",
  "game.buy.pop.current-balance": "當前餘額",
  "game.buy.pop.order-detail": "交易概覽",
  "game.buy.pop.m-coin-balance": "M幣餘額",
  "game.buy.pop.m-coupon-balance": "M券餘額",
  "game.buy.pop.no-balance": "餘額不足，去充值",
  "game.buy.pop.confirm": "確認購買",
  "game.publicity.wait": "敬請期待",
  "game.publicity.CompatibleWithAndroidOnly": "僅支援Android系統",
  "game.publicity.try": "下載試玩版",
  "game.publicity.try.start": "開始試玩",
  "game.publicity.see.all": "查看全部",
  "game.preSell.tips": "正式上線",
  "game.preSell.tips.sub": "預售中",
  "game.preSell.tips.sub.time": "限時預售中",
  "game.removed": "該遊戲已下架",
  "game.back": "返回",
  // 新增
  "game.buy.pop.cancel": "取消",
  "game.buy.pop.success": "購買成功",
  // 公共部分
  "common.account-center": "個人中心",
  "common.logout": "退出登入",
  "common.USD": "美金",
  // 登入彈窗
  "login.auth.tips": "請先登入",
  "login.welcome": "歡迎來到",
  "login.welcome.title": "麻涩部",
  "login.tab.phone": "手機號碼",
  "login.tab.email": "電子信箱",
  "login.tab.electronicDocument": "電子憑證",
  "login.form.phone": "手機號碼",
  "login.form.phone.validate": "請輸入正確的手機號",
  "login.form.phone.placeholder": "請輸入手機號碼",
  "login.form.captcha": "驗證碼",
  "login.form.captcha.send-success": "驗證碼發送成功",
  "login.form.captcha.send-failed": "驗證碼發送失敗",
  "login.form.captcha.placeholder": "請輸入驗證碼",
  "login.form.captcha.send": "取得驗證碼",
  "login.form.captcha.image": "圖片驗證碼",
  "login.form.captcha.image.placeholder": "請輸入圖形驗證碼",
  "login.form.captcha.second": "秒後重新獲取",
  "login.form.model.password.or": "或",
  "login.form.model.captcha.btn": "驗證碼登入",
  "login.form.model.password.btn": "密碼登入",
  "login.form.model.password.bind": "密碼綁定",
  "login.form.model.captcha.bind": "驗證碼綁定",
  "login.form.model.bind.success": "綁定完成",
  "login.form.model.bind.unsuccess": "綁定未完成",
  "login.form.model.back.home": "返回首頁",
  "login.form.model.try.Again": "再次嘗試",
  "login.form.scan.try.Again": "驗證失敗，請重新嘗試",
  "login.form.scan.Again": "重新驗證",
  "login.form.inviter.label": "邀請碼",
  "login.form.inviter.placeholder": "新用戶選填",
  "login.form.password": "密碼",
  "login.form.password.new": "新密碼",
  "login.form.password.limit": "請輸入8位以上的密碼",
  "login.form.password.placeholder": "請輸入密碼",
  "login.form.forgot": "忘記密碼",
  "login.server.tips": "繼續使用即代表你同意 MASOBU麻涩部 的",
  "login.server.tips.sub": "服務條款",
  "login.privacy.tips": "並確認你已詳閱我們的",
  "login.privacy.tips.sub": "隱私權政策",
  "login.btn": "登入",
  "login.page.text": "登入頁",
  "login.btn.register": "註冊",
  "login.btn.continue": "繼續",
  "login.btn.confirm": "確認",
  "login.phone.register.tips": "尚未註冊的手機號驗證後自動註冊",
  "login.form.email": "信箱",
  "login.form.email.placeholder": "請輸入信箱地址",
  "login.form.email.validate": "請輸入正確的信箱",
  "login.form.confirm-password": "確認密碼",
  "login.form.confirm-password.validate": "兩次密碼不一緻",
  "login.email.register.tips": "還没加入我們嗎",
  "login.email.register.tips.btn": "建立免費帳號",
  "login.email.login.tips": "已有賬號了",
  "login.email.login.btn": "登入",
  "login.forgot.save.btn": "保存",
  "login.bind.save.btn": "確認",
  "login.modify.mode-select": "驗證方式",
  "login.modify.save.btn": "保存",
  // 新增
  "login.modify.save.success": "修改成功",
  // web-sdk 登陆
  "login.welcome.text": "歡迎來到",
  "login.highlight.name": "麻涩部",
  "login.button.existing.account": "登入現有帳號",
  "login.button.play.directly": "直接遊玩",
  "login.button.bind.account": "綁定帳號",
  "login.button.bind.or": "或",
  "login.button.bind.continue": "繼續",
  "login.button.bind.password": "密碼綁定",
  "login.button.bind.codekey": "驗證碼綁定",
  "bind.status.success": "綁定成功",
  "bind.status.fail": "綁定失敗",
  "bind.button.backHome": "返回首頁",
  "bind.button.enter.game": "進入遊戲",
  "bind.button.retry": "再次嘗試",
  "bind.account.bind.title": "綁定帳號",
  "bind.account.bind.tip": "需要綁定帳號才可進行消費動作",
  "bind.account.bind.button.bind": "綁定帳號",
  "bind.account.bind.button.login": "登入現有帳號",
  "login.form.phone.again.placeholder": "請再次輸入密碼",
  // 站內信
  "notice.title": "通知中心",
  "notice.tab.account": "帳戶通知",
  "notice.tab.benefits": "活動福利",
  "notice.tab.system": "公告",
  "notice.no-message": "暫無消息",
  "notice.more": "檢視更多",
  "notice.date": "日期",
  // 年齡
  "age.limit.title": "您是否已滿 18 歲？",
  "age.limit.sub-top": "網站包含成人內容，請確認您已成年。",
  "age.limit.sub-bottom":
    "本網站包含色情內容和裸露。點擊『是的 - 我已滿 18 歲』即表示您已閱讀並同意我們的政策和指引，並確認您已達到合法觀看年齡。",
  "age.limit.confirm": "已滿18歲",
  "age.limit.cancel": "未滿18歲",
  "age.limit.toast": "很抱歉，未滿18歲用戶不可進入網站",
  // 邀請
  "inviter.link": "邀請鏈接",
  "inviter.code": "邀請碼",
  "inviter.btn": "複製",
  "inviter.tips.top": "您可以選擇分享給好友，好友直接註冊",
  "inviter.tips.bottom": "或者分享邀請碼給好友，好友註冊時填寫邀請碼",
  // 新增
  "search.close": "關閉",
  "search.no-result": "暫無相關結果",
  "search.title": "搜索結果",
  "search.placeholder": "請輸入",
  // 任務限製
  "task.limit.count.get": "獲得獎勵次數",
  "task.limit.count": "剩餘獎勵次數",
  "game.purchase.not.exist": "該遊戲不存在",
  // 道具購買
  "props.title": "購買",
  "props.name.label": "道具名稱",
  "props.count.label": "道具數量",
  "props.price.label": "金額",
  "props.m.coin": "M幣",
  "props.m.coupons": "M券",
  "props.m.coins.balance": "M幣餘額",
  "props.m.coupons.balance": "M券餘額",
  "props.m.coins.pay": "M幣支付",
  "props.m.coupons.discount": "M券抵扣",
  "props.m.coupons.discount.limit": "上限",
  "props.m.no.enough": "餘額不足，還需充值",
  "props.buy": "購買",
  "props.buy.success": "購買成功",
  "props.charge": "前往充值",
  "props.tips": "虛擬商品一經出售不與退款",
  "props.no.exist": "該道具不存在",
  "props.limit.left": "低於",
  "props.limit.right": "M幣支付免確認",
  "activity.time": "活動時間",
  "activity.btn.open": "查看活動詳情",
  "activity.btn.close": "收合",
  "activity.btn.top": "回頂部",
  "account.virtual.m.coupons": "優惠券",
  "coming.soon": "即將上線",
  "account.virtual.m.coin": "幣",
  "account.virtual.m.points": "券",
  account: "賬號",
  "layout.header.login": "立即遊玩",
  "footer.join": "加入MASOBU社群頻道",
  "footer.info": "信息",
  "coupon.page.title": "我的優惠券",
  "coupon.page.record": "帳戶記錄",
  "coupon.page.memberTitle": "可使用項目",
  "coupon.page.btn": "兌換優惠券",
  "coupon.page.coupon.placeholder": "請輸入優惠碼",
  "coupon.page.tab.valid": "可用優惠券",
  "coupon.page.tab.invalid": "已失效優惠券",
  "coupon.page.game.title": "可使用遊戲",
  "coupon.page.game.tips": "已使用或已失效優惠券將在30天後消失",
  "coupon.exchange.confirm": "确定",
  "coupon.exchange.cancel": "取消",
  "coupon.exchange.know": "知道了",
  "coupon.card.type": "折價券",
  "coupon.card.limit.date": "有效期限至",
  "coupon.card.use": "使用",
  "coupon.card.used": "已使用",
  "coupon.card.range.all": "所有遊戲可使用",
  "coupon.card.range.part": "部分遊戲可使用",
  "coupon.card.range.online": "線上遊戲可使用",
  "coupon.card.range.offline": "線下遊戲可使用",
  "coupon.card.range.h5": "H5遊戲可使用",
  "coupon.card.expired": "已失效",
  "coupon.use.m.discount": "M券抵扣",
  "coupon.use.coupon": "優惠券",
  "coupon.use.no": "暫無優惠",
  "coupon.select.title": "可使用優惠券",
  "coupon.select.btn.confirm": "确定",
  "coupon.select.btn.cancel": "取消",
  "coupon.select.discount": "抵扣",
  "coupon.card.noData": "目前沒有可使用的優惠券",
  "vip.card": "會員卡",
  "vip.card.earnings": "會員卡權益",
  "vip.card.earningsContent": "解鎖月卡享專屬權益",
  "vip.card.couponText": "有优惠券可使用",
  "vip.card.timeLeft": "剩餘時間: ",
  "vip.card.surplus": "剩餘: ",
  "vip.card.activeSurplus": "生效中: 剩餘",
  "vip.card.alreadyOwned": "已擁有",
  "vip.card.day": "天",
  "vip.card.popupLv": "澀點等級",
  "vip.card.popupTip": "新春限定 - 權益升级至60天",
  "vip.card.popupLv01": "正式部員",
  "vip.card.popupLv02": "初級部員",
  "vip.card.popupLv03": "中級部員",
  "vip.card.popupLv04": "高級部員",
  "vip.card.popupLabel01": "涩点点系列全免費",
  "vip.card.popupQuarterLabel01": "真人遊戲 1折優惠券",
  "vip.card.popupQuarterLabel02": "贈送 {count} M能量",
  "vip.card.popupQuarterLabel03": "麻豆國際版 VIP {count}金幣",
  "vip.card.popupQuarterLabel04": "点点系列女優 無限玩",
  "vip.card.popupQuarterLabel05": "專屬VPN 會員{count}個月",
  "vip.card.popupQuarterLabel06": "充值即可 加贈M幣",
  "vip.card.popupQuarterLabel07": "点点未公開 圖鑑季度贈",
  "vip.card.popupQuarterLabel08": "麻涩部遊戲 試玩先鋒",
  "vip.card.popupQuarterLabel09": "雙週贈 單機5折優惠券",
  "vip.card.popupQuarterLabel10": "季度贈送 麻豆傳媒實體物",
  "vip.card.tag01": "涩点点免費暢玩",
  "vip.card.tag02": "優惠券先鋒",
  "vip.card.tag03": "M幣加贈",
  "vip.card.tag04": "領取實體物",
  "vip.card.tag05": "試玩前鋒",
  "vip.card.vipLv1": "青銅級",
  "vip.card.vipLv2": "白銀級",
  "vip.card.vipLv3": "黃金級",
  "vip.card.vipLv4": "鑽石級",
  "vip.card.membershipLvExplain": "會員等級說明",
  "vip.card.currentLv": "目前等級",
  "vip.card.privilegedContent": "特权内容",
  "vip.card.rebates": "返贈前 { count } M幣",
  "vip.card.energy": "贈送 {count} M能量",
  "vip.card.payBtn01": "原價 { listPrice } ",
  "vip.card.payBtn02": "特價 { salePrice } / { validDays } 天",
  "vip.month": "月",
  "vip.card.masobuEarnings": "麻涩部點卡 {day}天權益",
  "vip.card.vipExchangeBtn": "權益兌換步驟",
  "vip.card.benefitsRedemptionGuidelines": "權益兌換須知",
  "vip.card.vipExchangePopupTipTitle01": "實體物寄送登記流程",
  "vip.card.vipExchangePopupTipSecondTitle01": "1. 填寫寄送資訊",
  "vip.card.vipExchangePopupTipText01":
    "請複製以下模板，填寫完整後務必確認信息正確，以確保順利寄送。",
  "vip.card.vipExchangePopupTipSecondTitle02": "寄送資訊模板：",
  "vip.card.vipExchangePopupTipText02":
    "<item> • 麻涩部 UID（請提供官網上顯示的完整 UID）</item><item>• 收件人姓名（需與身分證姓名一致）</item><item>• 手機號碼（請填寫正確手機號碼，以便快遞聯繫）</item><item>• 收件地址（請提供詳細的省/市/區 + 具體街道地址）</item><item>• 郵遞區號（請填寫正確的區域郵遞區號）</item>",
  "vip.card.vipExchangePopupTipSecondTitle03": "2. 回傳完整資訊",
  "vip.card.vipExchangePopupTipText03":
    "請聯繫麻涩部官方客服，並回傳完整且正確的寄送資訊，以便安排配送。",
  "vip.card.vipExchangePopupTipSecondTitle04": "3. 資訊安全保障",
  "vip.card.vipExchangePopupTipText04":
    "麻涩部承諾保護您的個人隱私，所有資訊僅用於寄送，不會用於其他用途。",
  "vip.card.vipExchangePopupTipTitle02": "專屬會員及金幣領取流程",
  "vip.card.vipExchangePopupTipSecondTitle05": "1. 進入平台並註冊",
  "vip.card.vipExchangePopupTipText05":
    "點選 M代表加速器 / 麻豆傳媒國際版，完成會員註冊。",
  "vip.card.vipExchangePopupTipSecondTitle06": "2. 聯繫客服驗證",
  "vip.card.vipExchangePopupTipText06":
    " 註冊後，請聯繫 麻涩部官方客服，提供您的註冊郵箱。",
  "vip.card.vipExchangePopupTipSecondTitle07": "3. 權益發放",
  "vip.card.vipExchangePopupTipText07":
    "我們將在 5 個工作天內 開通您的會員權益或發放專屬金幣。",
  "vip.card.vipExchangePopupTipSecondTitle08": "4. 通知確認",
  "vip.card.vipExchangePopupTipText08": " 兌換完成後，您將收到站內信通知。",
  "vip.card.vipExchangePopupTipSecondTitle09": "5. 問題諮詢",
  "vip.card.vipExchangePopupTipText09":
    "如有任何問題，請隨時聯繫 麻涩部官方客服。",
  "vip.card.vipExchangePopupBtn01": "M代表加速器",
  "vip.card.vipExchangePopupBtn02": "麻豆傳媒國際版",
  "vip.card.vipExchangePopupBtn03": "聯繫客服",
  "vip.card.masobuSecondEarnings":
    "購買麻涩部點卡之後可以解鎖M能量，可提升涩點等級，並且可以免費遊玩涩/恋点点全系列",
  "vip.vipPrefecture.lvTip": "等級說明",
  "vip.vipPrefecture.renewalTip": "續訂Masobu點卡",
  "vip.vipPrefecture.renewalPopupTip":
    "「別急，您的月卡還在有效期內哦！剩餘**[{count}]天**，等到期後再來續卡吧～我們會一直等著您！」",
  "vip.vipPrefecture.tab01": "遊戲庫",
  "vip.vipPrefecture.tab02": "願望清單",
  "vip.vipPrefecture.tab03": "任務列表",
  "vip.vipPrefecture.btn01": "帳號憑證",
  "vip.vipPrefecture.btn02": "切換帳號",
  "vip.vipPrefecture.btn03": "找回帳號",
  "vip.vipPrefecture.boundUp": "已綁定",
  "vip.vipPrefecture.tipText": "記得保存憑證，隨時找回賬號哦～",
  "signIn.signAgain": "補簽",
  "signIn.energyValue": "能量值",
  "signIn.signInComplete": "簽到完成",
  "signIn.title": "任務面板",
  "signIn.signInRuleText":
    "签到任务每7天循环一次，若有漏签便不可往后签到，您可花费5 M币进行补签。",
  "signIn.signInRule": "補簽到說明規則",
  "signIn.claimReward": "領取獎勵",
  "signIn.completed": "已完成",

  "recharge.card.unlock.title": "你已解鎖隱藏優惠！",
  "recharge.card.unlock.subtitle": "開卡立享澀點點全系列免費玩",
  "recharge.card.unlock.price.original": "原價{amount} ",
  "recharge.card.unlock.price.new": "只要 {amount} ",
  "recharge.product.pay.unit": "NTD",
  "recharge.card.unlock.chance": "還剩 {{count}} 次機會",
  "transaction.title": "交易概覽",
  "transaction.item.label": "項目",
  "transaction.price.label": "價格",
  "transaction.price.value": "{amount}$",
  "transaction.payment.method": "選擇支付方式",
  "transaction.button.confirm": "確認支付",
  "transaction.button.cancel": "取消",
  "transaction.unit": "月卡",

  "recharge.card.title": "隱藏優惠最後機會！",
  "recharge.card.unlock.message": "你已解鎖隱藏優惠！",
  "recharge.card.price.original": "原價{count}{currency}",
  "recharge.card.price.current": "只要{count}{currency}",
  "recharge.card.price.combined":
    "原價{originalPrice}{currency} 只要{currentPrice}{currency}",
  "recharge.card.button.text": "點擊前往購買",
  "recharge.card.benefit.prefix": "開卡立享",
  "recharge.card.benefit.highlight": "澀點點、戀點點遊戲全系列",
  "recharge.card.benefit.suffix": "免費玩",
  "recharge.card.benefit.tips": "*虛擬商品一經售出不與退款",
  "recharge.card.payment.error": "支付異常，請重試",
  "recharge.card.payment.selectMethod": "請選擇支付方式",
  "game.detail.img": "遊戲畫面",
  "game.Platformsil.ios": "僅支持iOS平台",
  "game.Platformsil.android": "僅支持Android平台",
  noVideo: "暫無宣傳視頻",
  noDownload: "暫無此平台下載鏈接",
  "recharge.bind.secondTitle": "需要綁定帳號才可進行消費動作",

  "member.accountCredentials.tips":
    "如帳號丟失，請到「會員專區」找回，上傳或掃描憑證",
  "member.accountCredentials.btn": "保存帳號憑證至裝置",
  "member.recoverAccount": "找回帳號",
  "member.recoverAccount.btn01": "上傳憑證",
  "member.recoverAccount.btn02": "掃描憑證",
  "qrcode.fetch.failed": "獲取二維碼失敗",
  "image.generation.failed": "生成圖片失敗",
  // 扫码登录相关
  "qrcode.scan.title": "掃描登錄",
  "qrcode.scan.guide": "對準二維碼即可自動識別",
  "qrcode.scan.album": "相冊",
  "qrcode.scan.cancel": "取消",
  "qrcode.error.device": "對不起：未識別到掃描設備!",
  "qrcode.error.camera": "啟動相機播放失敗，請重試",
  "qrcode.error.video": "視頻播放錯誤，請重試",
  "qrcode.error.https":
    "獲取瀏覽器錄音功能，因安全性問題，需要在localhost 或 127.0.0.1 或 https 下才能獲取權限！",
  "qrcode.error.invalid": "無效的二維碼，請重試",
  "qrcode.error.invalid2": "無效的二維碼",
  "qrcode.error.processing": "圖片處理失敗，請重試",
  "qrcode.error.reading": "文件讀取失敗，請重試",
  "qrcode.error.init": "初始化攝像頭失敗，請刷新重試",
  "qrcode.error.login": "登錄失敗，請重試",
  "component.noticeIcon.empty": "暫無資料",

  // 嵌入式
  "Recharge.title": "充值帳號",
  "Recharge.error.general": "操作失敗，請稍後重試",
  "Recharge.balance.coin": "M幣餘額",
  "Recharge.balance.coupon": "M券餘額",
  "Recharge.payment.methods": "選擇支付方式",
  "Recharge.payment.status.to_pay": "等待支付",
  "Recharge.payment.status.in_pay": "支付處理中",
  "Recharge.payment.status.pay_success": "支付成功",
  "Recharge.payment.status.pay_fail": "支付失敗",
  "Recharge.payment.status.pay_cancel": "支付已取消",
  "Recharge.payment.status.refund": "已退款",
  "Recharge.payment.success": "支付成功！",
  "Recharge.payment.fail": "支付失敗！",
  "Recharge.product.title": "商品",
  "Recharge.product.price": "價格",
  "Recharge.product.first": "首充專享",
  "Recharge.product.coin": "M幣",
  "Recharge.product.reward": "贈送 {amount} M券",
  "Recharge.button.next": "下一步",
  "Recharge.button.cancel": "取消",
  "Recharge.button.confirm": "確認支付",
  "Recharge.button.close": "關閉",
  "Recharge.tips": "*虛擬商品一經出售不與退款",
  "Recharge.noPayMethod": "暫無可用支付方式",

  "GameItemPurchase.propsTitle": "購買道具",
  "GameItemPurchase.propsNameLabel": "道具名稱",
  "GameItemPurchase.propsCountLabel": "數量",
  "GameItemPurchase.propsPriceLabel": "價格",
  "GameItemPurchase.propsMCoinsBalance": "M幣餘額：",
  "GameItemPurchase.propsMCouponsBalance": "M券餘額：",
  "GameItemPurchase.propsMCoinsPay": "M幣支付",
  "GameItemPurchase.propsMCouponsDiscount": "M券抵扣",
  "GameItemPurchase.propsMCouponsDiscountLimit": "最多可抵扣",
  "GameItemPurchase.propsMNoEnough": "餘額不足，還需充值",
  "GameItemPurchase.propsMCoin": "M幣",
  "GameItemPurchase.propsMCoupons": "M券",
  "GameItemPurchase.propsLimitLeft": "低於",
  "GameItemPurchase.propsLimitRight": "M幣支付免確認",
  "GameItemPurchase.propsBuy": "購買",
  "GameItemPurchase.propsCharge": "去充值",
  "GameItemPurchase.propsTips": "*虛擬商品一經出售不與退款",
  "GameItemPurchase.propsBuySuccess": "購買成功",

  rechargeCardTitle: "隱藏優惠最後機會！",
  rechargeCardUnlockTitle: "你已解鎖隱藏優惠！",
  rechargeCardPriceOriginal: "原價 {amount} ",
  rechargeCardPriceNew: "特惠價 {amount} ",
  rechargeCardBenefitPrefix: "開卡即可享受",
  rechargeCardBenefitHighlight: "色點點、戀點點遊戲全系列",
  rechargeCardBenefitSuffix: "永久免費暢玩特權",
  rechargeCardButtonText: "立即購買",
  rechargeCardImageAlt: "VIP卡優惠",
  rechargeCardNoChannels: "暫無可用支付渠道",
  rechargePaySuccessAlt: "支付成功",
  rechargePaySuccessText: "完成！",
  rechargePayFailAlt: "支付失敗",
  rechargePayFailText: "失敗！",

  paymentTitle: "支付確認",
  paymentProductLabel: "商品",
  paymentPriceLabel: "價格",
  paymentUnit: "VIP會員卡",
  paymentMethodTitle: "支付方式",
  paymentMethodAlt: "{method}支付",
  paymentConfirmButton: "確認支付",
  paymentCancelButton: "取消",
  paymentFooterNote: "支付即表示同意服務條款",
  paymentSelectMethodFirst: "請選擇支付方式",
  paymentFailMessage: "支付失敗，請重試",
  paymentSystemError: "系統錯誤，請稍後重試",
  paymentProcessing: "支付處理中...",
  paymentSuccess: "支付成功！",
  paymentAmount: "支付金額",
  paymentOrderNumber: "訂單號",
  paymentTime: "支付時間",
};
