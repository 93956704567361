import { Toast } from "@nutui/nutui-react";

import {
  generateUUID,
  getMobileOperatingSystem,
  getSDKMobileOperatingSystem,
} from "@/utils/common";
import { removeCookie } from "@/utils/editorUtils";
import { message } from "antd";
import JSONbig from "json-bigint";
import { AxiosResponse, RequestOptions, getLocale, history } from "umi";
import avgHandler from "./avgHandler";
import { StorageKeys } from "./storage";
const JSONbigString = JSONbig({ storeAsString: true });

const HttpMessage = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  405: "请求方法不被允许。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
};

const CodeMessage = {
  SUCCESS: 200,
  NO_AUTH: 401,
};
const langMap: any = {
  "zh-CN": "zh_CN",
  "zh-TW": "zh_TW",
  "en-US": "en",
};

export { CodeMessage, HttpMessage };

// 错误抛出
export const errorThrower = (res: ResultData<any>) => {
  const { code, data, message } = res;
  if (code !== CodeMessage.SUCCESS) {
    const error = { name: "MyError", info: { code, data, message } };
    throw error; // 抛出自制的错误
  }
};

// 错误接收及处理
export const errorHandler = (error: any, opts: any) => {
  console.log("error: ", error);
  if (opts?.skipErrorHandler) throw error;
  if (error.name === "MyError") {
    const errorInfo: ResultData<any> | undefined = error.info;
  } else if (error.response) {
    // Axios 的错误
    // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
    Toast.show({
      icon: "fail",
      content: "服务异常",
    });
    console.error(
      `Response status: ${error.response.status} ${
        HttpMessage[error.response.status as keyof typeof HttpMessage]
      }`
    );
  } else if (error.request) {
    // 请求已经成功发起，但没有收到响应
    // \`error.request\` 在浏览器中是 XMLHttpRequest 的实例，
    // 而在node.js中是 http.ClientRequest 的实例
    Toast.show({
      icon: "fail",
      content: "网络异常",
    });
    console.error("None response! Please retry.");
  } else {
    // 发送请求时出了点问题
    Toast.show({
      icon: "fail",
      content: "请求错误",
    });
    console.error("Request error, please retry.");
  }
  throw error;
};

// 请求拦截器
export const requestInterceptors = [
  async (config: RequestOptions) => {
    let token = localStorage.getItem(StorageKeys.TOKEN);

    // 获取设备ID - 优先使用 SDK 提供的，其次使用本地存储的，最后生成新的
    let deviceId = avgHandler.isSDK()
      ? avgHandler.getDeviceId()
      : localStorage.getItem(StorageKeys.DEVICE_ID);

    // 再次兜底
    if (!deviceId) {
      try {
        // 使用改进的指纹生成方法
        deviceId = await generateUUID();
        localStorage.setItem(StorageKeys.DEVICE_ID, deviceId);
      } catch (error) {
        console.error("指纹生成失败", error);
      }
    }

    const deviceType = avgHandler.isSDK()
      ? avgHandler.getDeviceType()
      : navigator.platform;

    const lang = getLocale();
    const sysType = avgHandler.isSDK()
      ? getSDKMobileOperatingSystem()
      : getMobileOperatingSystem();

    if (avgHandler.isSDK()) {
      token = avgHandler.getToken() || token;
    }
    // 当环境为unity时 游客绑定参数带token
    if (avgHandler.isSDK() && config.data?.tokenInfo) {
      console.log(">>>>>>>>>>tokeninfo", config.data);
      token = config.data.tokenInfo;
      // 过滤token参数
      const { tokenInfo, ...filterData } = config.data;
      config.data = filterData;
    }

    // 获取浏览器时区
    let timeZone = "";
    try {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch {}

    const _headers: any = {
      "C-Lang": langMap[lang as keyof typeof langMap],
      "C-Device-Id": deviceId,
      "C-Device-Type": deviceType,
      "C-Sys-Type": sysType,
      "C-Time-Zone": timeZone,
      "C-Client-Version": VERSION,
    };
    if (token) {
      _headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers = {
      ...config.headers,
      "Content-type": "application/json",
      ..._headers,
    };

    config.transformResponse = [(data) => data];

    return { ...config };
  },
];

// 响应拦截器
export const responseInterceptors = [
  (response: AxiosResponse) => {
    response.data = JSONbigString.parse(response.data);
    const { code, data, msg } = response.data;
    response.data.success = code === CodeMessage.SUCCESS;
    if (code === CodeMessage.SUCCESS) {
      return response;
    }
    if (code === CodeMessage.NO_AUTH) {
      const clearAuth = () => {
        localStorage.removeItem(StorageKeys.TOKEN);
        removeCookie(StorageKeys.TOKEN);
        localStorage.removeItem(StorageKeys.DEVICE_ID);
        window.location.href = "/";
      };
      if (avgHandler.isSDK()) {
        avgHandler.openLoginPage();
      } else {
        clearAuth();
        history.replace("/");
      }
    } else {
      // 在PC平台中，message.error 好像无效，所有添加 window.AvgHandler.message_error 通知 SDK 显示提示
      if (avgHandler.isSDK()) {
        avgHandler.message_error(msg);
      }
      message.error(msg);
    }
    return Promise.reject(response.data);
  },
];
