import { getLocale } from "umi";
const isBrowser = typeof window !== "undefined" && !!window.document;
// localStorage key
export enum StorageKeys {
  TOKEN = 'TOKEN',
  u_id = 'u_id',
  LANG = 'LANG',
  LIMIT = 'LIMIT',
  INVITER_USER_ID = 'inviterUserId',
  INVITER_CODE = 'inviterCode',
  DEVICE_ID = 'DEVICE_ID',
  CURRENT_ORIGIN = 'CURRENT_ORIGIN',
  PLATFORM = 'PLATFORM',
  UMI_LOCALE = 'umi_locale',
  DC = 'dc',
  MAX_M_COUPON = 'MAX_M_COUPON',
  ORIGIN_URL = 'ORIGIN_URL',
  GAME_LIST = "GAME_LIST",
}

// 产品单位
export const productUnitMap: any = {
  'zh-CN': 'CNY',
  'zh-TW': 'NTD',
  'en-US': 'USD',
};

// 语言下拉选择
export const LangsOptions = [
  {
    label: "简体中文",
    value: "zh_CN",
  },
  {
    label: "繁體中文",
    value: "zh_TW",
  },
  {
    label: "English",
    value: "en_US",
  },
];

// 号码前缀下拉选择
export const PhonePrevOptions = [
  {
    label: "+86",
    value: "86",
  },
  {
    label: "+886",
    value: "886",
  },
  {
    label: "+852",
    value: "852",
  },
  {
    label: "+81",
    value: "81",
  },
  {
    label: "+1",
    value: "1",
  },
  {
    label: "+44",
    value: "44",
  },
  {
    label: "+82",
    value: "82",
  },
  {
    label: "+66",
    value: "66",
  },
];

const HttpMessage = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  405: "请求方法不被允许。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
};

const CodeMessage = {
  SUCCESS: 200,
  TKN0003: "TKN0003", //当前用户已被顶下线
  NOT_AUTHENTICATION: 401, //当前用户已被顶下线
  TKN0001: "TKN0001", //token 无效
  UU1001: "UU1001", //账号不存在
};

// 游戏名称 maps
const GameNameMaps: {
  [propName: string]: string;
} = {
  "zh-CN": "gameName",
  "zh-TW": "gameTraditionalName",
  "en-US": "gameEnglishName",
};

export function getGameName(options: any) {
  const lang = getLocale();
  console.log("lang: ", lang);
  const key = GameNameMaps[lang] || "gameName";
  return options[key];
}

export { CodeMessage, HttpMessage };

class GcpLocalStorage {
  setItem(key: string, value: any) {
    try {
      if (isBrowser) {
        localStorage.setItem(key, value);
      }
    } catch {}
  }
  getItem(key: string) {
    let res = null;
    try {
      if (isBrowser) {
        res = localStorage.getItem(key);
      }
    } catch {
      res = null;
    }
    return res;
  }
  removeItem(key: string) {
    try {
      if (isBrowser) {
        localStorage.removeItem(key);
      }
    } catch {}
  }
}

export const gcpLocalStorage = new GcpLocalStorage();
