// @ts-ignore
/* eslint-disable */
import { request } from "umi";

/** 收藏 收藏 GET /c/gameInteraction/addFavorite */
export async function addFavorite(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.addFavoriteParams,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/gameInteraction/addFavorite", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 点赞 点赞 GET /c/gameInteraction/addLike */
export async function addLike(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.addLikeParams,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/gameInteraction/addLike", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 取消点赞 取消点赞 GET /c/gameInteraction/cancelFavorite */
export async function cancelFavorite(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.cancelFavoriteParams,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/gameInteraction/cancelFavorite", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 取消点赞 取消点赞 GET /c/gameInteraction/cancelLike */
export async function cancelLike(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.cancelLikeParams,
  options?: { [key: string]: any }
) {
  return request<API.RVoid>("/c/gameInteraction/cancelLike", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 游戏评论列表 游戏评论列表 GET /c/gameInteraction/queryGameCommentList */
export async function queryGameCommentList(params: {
  gameName?: string;
  gameId: number | string;
  parentId?: number | string;
  pageNum: number;
  pageSize: number;
}) {
  return request<API.RListGcpGameComment>(
    "/c/gameInteraction/queryGameCommentList",
    {
      method: "GET",
      params,
    }
  );
}

/** 查询收藏列表 查询收藏列表 GET /c/gameInteraction/queryGameFavorite */
export async function queryGameFavorite(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.queryGameFavoriteParams,
  options?: { [key: string]: any }
) {
  return request<API.RListGcpGameFavorite>(
    "/c/gameInteraction/queryGameFavorite",
    {
      method: "GET",
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** 查询游戏点赞 查询游戏点赞 GET /c/gameInteraction/queryGameLike */
export async function queryGameLike(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.queryGameLikeParams,
  options?: { [key: string]: any }
) {
  return request<API.RListGcpGameLike>("/c/gameInteraction/queryGameLike", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

// 游戏播放或者下载登记
export async function addDownloadAndPlay(data: {
  gameId: string | number;
  optType: string;
  dc: string;
}) {
  return request("/c/gameInteraction/addDownloadAndPlay", {
    method: "POST",
    data,
  });
}

// 我的游戏点赞列表
export async function myGameLikeList() {
  return request<API.GetGameInfoRespT>("/c/gameInteraction/myGameLikeList", {
    method: "GET",
  });
}

// 我的游戏收藏列表
export async function myGameFavoriteList() {
  return request<API.GetGameInfoRespT>(
    "/c/gameInteraction/myGameFavoriteList",
    {
      method: "GET",
    }
  );
}

// 回复评论
export async function replyComment(data: {
  gameId: number | string;
  content: string;
  parentId?: number | string;
}) {
  return request("/c/gameInteraction/replyComment", {
    method: "POST",
    data,
  });
}

// 历史游戏列表
export function historyList() {
  return request<API.CenterGameListRes>(
    "/c/gameInteraction/myGameDownloadAndPlayHistory",
    {
      method: "GET",
    }
  );
}

// 购买游戏列表
export function buyList() {
  return request<API.CenterGameListRes>("/c/gameInteraction/myBuyGameHistory", {
    method: "GET",
  });
}
