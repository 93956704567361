interface storageProps {
  userInfo: string;
  token: string;
  projectInfo: string;
}

export enum StorageKeys {
  TOKEN = 'TOKEN',
  LANG = 'LANG',
  LIMIT = 'LIMIT',
  INVITER_USER_ID = 'inviterUserId',
  DEVICE_ID = 'DEVICE_ID',
  PLATFORM = 'PLATFORM',
  DC = 'dc'
}


