import common from './zh-CN/common';
import settings from './zh-CN/settings';

export default {
  ...common,
  ...settings,
  // 'navBar.lang': '语言',
  // 'layout.user.link.help': '帮助',
  // 'layout.user.link.privacy': '隐私',
  // 'layout.user.link.terms': '条款',
  // 'app.preview.down.block': '下载此页面到本地项目',
  // 'app.welcome.link.fetch-blocks': '获取全部区块',
  // 'app.welcome.link.block-list': '基于 block 开发，快速构建标准页面',
  // ...pages,
  // ...globalHeader,
  // ...menu,
  // ...settingDrawer,
  // ...settings,
  // ...pwa,
  // ...component,
};
